import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert as BootstrapAlert,
  Button,
  ButtonGroup,
  Container,
  Col,
  Form,
  // OverlayTrigger,
  ToggleButton,
  // Tooltip,
} from "react-bootstrap";
import { Alert } from "../../components";
import { useAppContext } from "../../context/appContext";
import "react-datepicker/dist/react-datepicker.css";
import "./AddSession.css";
import { ShotDataContext } from "../../context/shotDataContext";

const AddSession = () => {
  const [files, setFiles] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [alert, setAlert] = useState(null);
  const [radioValue, setRadioValue] = useState('1');
  const [errors, setErrors] = useState(false);
  const radios = [
    { name: 'GSPro', value: '1', filetype: 'gspro' },
    { name: 'FSX Pro', value: '2', filetype: 'fsxPro' },
  ];
  // const { setRefreshData } = useShotData();
  const { setRefreshData, setSessionWasImported } = useContext(ShotDataContext);

  const {
    createSession,
    isLoading,
    showAlert,
    displayCustomAlert,
  } = useAppContext();

  const onFileChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    setFiles(Array.from(event.target.files));
  };

  const selectedRadio = radios.find((radio) => radio.value === radioValue);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files) {
      displayCustomAlert("Please Select a File");
      return;
    }
    setUploadedFiles([]);
    setErrors(false);

    const result = await createSession(files, selectedRadio.filetype);

    if (result.success) {
      const created = result.data.createdSessions.map((session) => ({
        success: true,
        sessionId: session.sessionId,
        filename: session.filename,
        numberOfShots: session.numberOfShots,
        clubs: session.clubs,
      }));

      const failed = result.data.failedFiles.map((file) => ({
        success: false,
        filename: file.filename,
        errorMessage: file.errorMessage,
      }));

      const existing = result.data.existingFiles.map((file) => ({
        success: false,
        sessionId: file.sessionId,
        filename: file.filename,
        numberOfShots: file.numberOfShots,
        clubs: file.clubs,
        existing: true,
      }));

      setUploadedFiles([...created, ...failed, ...existing]);
      if (failed.length > 0) {
        setErrors(true);
        console.log("setting errors to true")
      }

      // Set the alert state based on the import results
      if (failed.length === 0 && existing.length === 0) {
        setAlert({
          type: "success",
          message: "All files imported successfully.",
        });
        // console.log("toggle refresh data")
        setRefreshData(prev => !prev);
        setSessionWasImported(true);
      } else if (created.length > 0) {
        setAlert({
          type: "warning",
          message:
            "Some files loaded successfully, see results summary for detail. If the issue persists, please contact support.",
        });
        // console.log("toggle refresh data")
        setRefreshData(prev => !prev);
        setSessionWasImported(true);
      } else {
        setAlert({
          type: "danger",
          message:
            "Files did not load successfully, see results summary for detail. If the issue persists, please contact support.",
        });
      }
    } else {
      setUploadedFiles([
        ...uploadedFiles,
        { success: false, errorMessage: result.error.response.data.msg },
      ]);
      console.log("uploaded files", uploadedFiles);
    }

    setFiles(null);
    event.target.reset();
  };



  return (
    <Container>
      <style>
        {`  
        .btn-custom {
    background-color: #6366f1;
    color: #fff; // Add this line if you want the text color to be white
    text-decoration: none;
    // display: inline-block;
  }

  .btn-custom:hover {
    background-color:#5a5fd9; // Add a slightly darker color for hover effect
    color: #fff;
    text-decoration: none;
  }
  `}
      </style>
      <h2>Add Session from {selectedRadio.name}</h2>
      {showAlert && <Alert />}
      {alert && (
        <BootstrapAlert variant={alert.type}>{alert.message}</BootstrapAlert>
      )}
      <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
            className={radioValue === radio.value ? 'checked-class' : 'unchecked-class'}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Form onSubmit={handleSubmit}>
        <br />
        <Col md={6}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              accept=".csv"
              onChange={onFileChange}
              multiple
            />
          </Form.Group>
          <Form.Text id="passwordHelpBlock" muted>
            Please use original CSV files exported from GSPro/FSX Pro and do NOT rename
            or alter them. We use filename to check for duplicates and we also
            grab the date from it.{" "}
          </Form.Text>
          <Form.Group className="mt-1">
            <Form.Text id="passwordHelpBlock" muted>
              Multiple files can be loaded at once and you can drag and drop
              into the box.
            </Form.Text>

          </Form.Group>
        </Col>

        <Button
          variant="primary"
          type="submit"
          disabled={isLoading}
          className="mt-3 btn-custom"
        >
          {isLoading ? "Processing..." : "Submit"}
        </Button>
        <Col md={6}>
          <br />
          <Form.Group className="mt-1">

            <Form.Text id="passwordHelpBlock" muted>
              For other launch monitor files, you can try our generic file importer <Link to="/add-custom-session">here</Link> or I can easily update the app to add support for other file types.  Drop me an email with an example file from your launch monitor at {" "}
              <a href="mailto:steve@golfswingvision.com">steve@golfswingvision.com</a>
            </Form.Text>
          </Form.Group>
        </Col>
      </Form>
      {/* Render the table with imported data */}
      {uploadedFiles.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Status</th>
              {errors && (<th scope="col">Error</th>)}
              <th scope="col">File Name</th>
              <th scope="col"># Shots</th>
              <th scope="col">Clubs Used</th>
              <th scope="col">View</th>
            </tr>
          </thead>
          <tbody>
            {uploadedFiles.map((file, index) => (
              <tr key={index}>
                <td>
                  {file.success ? (
                    <span role="img" aria-label="green-check">
                      ✅
                    </span>
                  ) : file.existing ? (
                    "Previously imported"
                  ) : (
                    // <OverlayTrigger
                    //   key={index}
                    //   placement="top"
                    //   overlay={
                    //     <Tooltip id={`tooltip-${index}`}>
                    //       {file.errorMessage}
                    //     </Tooltip>
                    //   }
                    // >
                    <span role="img" aria-label="red-x">
                      ❌
                    </span>
                    // </OverlayTrigger>
                  )}
                </td>
                {errors && (<td>{file.errorMessage}</td>)}
                <td>{file.filename}</td>
                <td>{file.numberOfShots}</td>
                <td>
                  {file.clubs &&
                    file.clubs.map((club, idx) => (
                      <span key={idx}>
                        {club}
                        {idx !== file.clubs.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </td>
                {file.success ? (
                  <Link
                    to={`/session-stats/${file.sessionId}`}
                    className="btn btn-primary table-btn"
                  >
                    View Session
                  </Link>
                ) : (
                  "" // Render an empty string if the file import is not successful
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Container>
  );
};

export default AddSession;
