import { useEffect } from "react";
import NavLinks from "./NavLinks";
import './SubNavbar.css'
import {useAppContext} from '../context/appContext'
import introJs from "intro.js";
import "intro.js/introjs.css";

const SubNavbar = ({ toggleSidebar }) => {
  const { markTooltipAsSeen, user } = useAppContext();

  var showWalkthrough = false;
    // console.log("user.seenOnboardingTooltip: ", user.seenOnboardingTooltip);
    if (user) {
      showWalkthrough = !user.seenOnboardingTooltip; // Replace this with the actual logic to determine if the user is new
    }
    // const startSecondIntro = () => {
    //   const secondIntro = introJs();
    //   secondIntro.setOptions({
    //     exitOnEsc: false,
    //     exitOnOverlayClick: false,
    //     steps: [
    //       {
    //         title: "Time to tee off 🏌️",
    //         intro: "Head to Add Session to get started! ⛳️",
    //       },
    //     ],
    //   });
  
    //   secondIntro.onexit(() => {
    //     markTooltipAsSeen();
    //   });
    //   secondIntro.start();
    // };
  
    useEffect(() => {
      if (showWalkthrough) {
        const intro = introJs();
  
        intro.onexit(() => {
          markTooltipAsSeen();
        });
  
        intro.start();
      }
    }, [showWalkthrough]);



  return (
    <div className="sub-navbar">
        <div className="nav-links-container">
      <NavLinks 
      data-intro="Hello! 👋 Click on this link"
      data-step={7}

      />
      </div>
    </div>
  );
};

export default SubNavbar;
