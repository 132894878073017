import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Alert as BootstrapAlert,
    Button,
    DropdownButton,
    Dropdown,
    Container,
    Col,
    Form,
    Table,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";
import { AlertDismissable } from "../../components";
import { useAppContext } from "../../context/appContext";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import './AddManualSession.css'




const AddManualSession = () => {
    const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState([]);
    //   const [mappedHeaders, setMappedHeaders] = useState({});
    const [headerMappings, setHeaderMappings] = useState({});
    const [sessionDate, setSessionDate] = useState();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [alert, setAlert] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalResolve, setModalResolve] = useState(null);

    const displayConfirmationModal = (message) => {
        return new Promise((resolve) => {
            setShowModal(true);
            setModalMessage(message);
            setModalResolve(() => resolve); // Wrap resolve in a function to avoid calling it immediately
        });
    };

    const handleClose = () => {
        if (modalResolve) {
            modalResolve(false); // Resolve promise with false if user closes modal
        }
        setShowModal(false);
        setModalMessage('');
        setModalResolve(null);
    };

    const handleConfirm = () => {
        if (modalResolve) {
            modalResolve(true); // Resolve promise with true if user confirms
        }
        setShowModal(false);
        setModalMessage('');
        setModalResolve(null);
    };

    //don't change without adjusting in session controller
    const standardHeaders = [
        "Ignore this Field",
        "AoA",
        "Back Spin",
        "Ball Speed",
        "Carry",
        "Club",
        "Club Speed",
        "Descent Angle",
        "Dynamic Loft",
        "Face To Path",
        "Face To Target",
        "HLA",
        "Lie",
        "Loft",
        "Offline",
        "Path",
        "Peak Height",
        "Side Spin",
        "Smash Factor",
        "Total Distance",
        "VLA",
    ];


    useEffect(() => {
        setHeaderMappings(
            headers?.reduce(
                (acc, header) => ({
                    ...acc,
                    [header]: standardHeaders.find(sh => sh.toLowerCase() === header.toLowerCase()) || "",
                }),
                {}
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers]);

    const validateMappings = async () => {
        const requiredFields = ["Club", "Carry", "Offline"];
        const missingFields = requiredFields.filter(field => !Object.values(headerMappings).includes(field));

        if (missingFields.length > 0) {
            displayCustomAlertNoClear(`To import data, your file must include data for ${missingFields.join(', ')}`);
            return false;
        }

        // Filter out the ignored fields before checking for duplicates
        const nonIgnoredMappings = Object.values(headerMappings).filter(value => value !== "Ignore this Field");
        const duplicateValues = nonIgnoredMappings.filter((value, i, self) => value !== "" && self.indexOf(value) !== i);

        if (duplicateValues.length > 0) {
            displayCustomAlertNoClear(`${duplicateValues.join(', ')} has been selected multiple times. Each field can only be used once.`);
            return false;
        }

        //if any fields have headrMappings === "" then show a modal that warns that xx fields will not be imported and ask them to cancel or confirm
        //TODO
        const emptyMappings = Object.entries(headerMappings)
            .filter(([key, value]) => value === "")
            .map(([key, value]) => key);

        if (emptyMappings.length > 0) {
            console.log("calling modal")
            const userConfirmed = await displayConfirmationModal(`The following fields will not be imported: ${emptyMappings.join(', ')}`);
            if (!userConfirmed) {
                return false;
            }
        }

        return true;
    }

    const {
        checkForDuplicateSessions,
        getHeadersFromFile,
        createCustomSession,
        isLoading,
        showAlert,
        displayCustomAlertNoClear,
        clearAlertNow,
        // user 
    } = useAppContext();

    const handleDateChange = (date) => {
        setSessionDate(date);
    };

    const onFileChange = async (event) => {
        clearAlertNow();
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        // Check for duplicates and retrieve headers
        //TODO
        // const isDuplicate = await checkForDuplicateSessions(selectedFile, user);
        if (false) {
            // Display error and reset file selection
            displayCustomAlertNoClear("Duplicate session detected");
            setFile(null);
            event.target.value = null;
            return;
        }

        //TODO 
        const retrievedHeaders = await getHeadersFromFile(selectedFile);
        console.log("retrievedHeaders", retrievedHeaders);
        // const retrievedHeaders = ["Club", "Carry", "Offlinex"];
        setHeaders(retrievedHeaders);
    };

    const onHeaderMappingChange = (originalHeader, standardHeader) => {
        setHeaderMappings({
            ...headerMappings,
            [originalHeader]: standardHeader,
        });
    };

    useEffect(() => {
        if (showAlert) {
            window.scrollTo(0, 0);
        }
    }, [showAlert]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        clearAlertNow();

        if (!sessionDate) {
            displayCustomAlertNoClear("Please Select a Date");
            return;
        }

        if (!file) {
            displayCustomAlertNoClear("Please Select a File");
            return;
        }


        if (!(await validateMappings())) {
            return;
        }

        // console.log("headerMappings", headerMappings);
        const result = await createCustomSession(file, headerMappings, sessionDate);

        if (result.success) {
            const created = result.data.createdSessions.map((session) => ({
                success: true,
                sessionId: session.sessionId,
                filename: session.filename,
                numberOfShots: session.numberOfShots,
                clubs: session.clubs,
            }));

            const failed = result.data.failedFiles.map((file) => ({
                success: false,
                filename: file.filename,
                errorMessage: file.errorMessage,
            }));

            const existing = result.data.existingFiles.map((file) => ({
                success: false,
                sessionId: file.sessionId,
                filename: file.filename,
                numberOfShots: file.numberOfShots,
                clubs: file.clubs,
                existing: true,
            }));

            setUploadedFiles([...uploadedFiles, ...created, ...failed, ...existing]);
            // Set the alert state based on the import results
            if (failed.length === 0 && existing.length === 0) {
                setAlert({
                    type: "success",
                    message: "All files imported successfully.",
                });
            } else if (created.length > 0) {
                setAlert({
                    type: "warning",
                    message:
                        "Some files loaded successfully, see results summary for detail. If the issue persists, please contact support.",
                });
            } else {
                setAlert({
                    type: "danger",
                    message:
                        "Files did not load successfully, see results summary for detail. If the issue persists, please contact support.",
                });
            }
        } else {
            setUploadedFiles([
                ...uploadedFiles,
                { success: false, errorMessage: result.error.response.data.msg },
            ]);
        }
    };

    return (

        <Container>
            <style>
                {`  
        .btn-block {
    background-color: #6366f1;
    color: #fff; // Add this line if you want the text color to be white
    text-decoration: none;
width:20%  }

  .btn-block:hover {
    background-color:#5a5fd9; // Add a slightly darker color for hover effect
    color: #fff;
    text-decoration: none;
  }
  `}
            </style>
            <h2>Session Import</h2>
            <p>Please note - files must be structured as a CSV file with the first row as the column headers.</p>

            {showAlert && <AlertDismissable />}
            {alert && (
                <BootstrapAlert variant={alert.type}>{alert.message}</BootstrapAlert>
            )}
            <br />
            <Form onSubmit={handleSubmit}>
                <Col md={6}>
                    <Form.Text id="passwordHelpBlock" muted>

                    </Form.Text>
                    <Form.Group>
                        <Form.Label>Date of Session</Form.Label>
                        <DatePicker selected={sessionDate} onChange={handleDateChange} />
                    </Form.Group>
                    <br />

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control type="file" accept=".csv" onChange={onFileChange} />
                    </Form.Group>
                    {headers?.length > 0 && (
                        <>
                            <br />

                            <h4>Select matching columns in your file to allow import</h4>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Your File...</th>
                                        <th>...Will Be Saved As</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {headers?.map((header, index) => (
                                        <tr key={index}>
                                            <td className={headerMappings[header] === "Ignore this Field" ? "strikethrough" : ""}>{header}</td>
                                            <td>
                                                <DropdownButton id={`headerMapping${index}`} variant="secondary btn-sm" title={headerMappings[header] || "Select Mapping"}
                                                    onSelect={(selectedKey) => onHeaderMappingChange(header, selectedKey)}
                                                >
                                                    {/* <Dropdown.Item eventKey="Ignore">
          Ignore this field
        </Dropdown.Item> */}
                                                    {standardHeaders?.map((standardHeader, idx) => (
                                                        <Dropdown.Item key={idx} eventKey={standardHeader}>
                                                            {standardHeader}
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>)}

                </Col>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={isLoading}
                    className="mt-3 btn-block"
                >
                    {isLoading ? "Processing..." : "Submit"}
                </Button>
            </Form>
            <Link to="/add-session">Back to Standard Import</Link>

            {uploadedFiles.length > 0 && (
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Status</th>
                            <th scope="col">File Name</th>
                            <th scope="col"># Shots</th>
                            <th scope="col">Clubs Used</th>
                            <th scope="col">View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadedFiles.map((file, index) => (
                            <tr key={index}>
                                <td>
                                    {file.success ? (
                                        <span role="img" aria-label="green-check">
                                            ✅
                                        </span>
                                    ) : file.existing ? (
                                        "Previously imported"
                                    ) : (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {file.errorMessage}
                                                </Tooltip>
                                            }
                                        >
                                            <span role="img" aria-label="red-x">
                                                ❌
                                            </span>
                                        </OverlayTrigger>
                                    )}
                                </td>
                                <td>{file.filename}</td>
                                <td>{file.numberOfShots}</td>
                                <td>
                                    {file.clubs &&
                                        file.clubs.map((club, idx) => (
                                            <span key={idx}>
                                                {club}
                                                {idx !== file.clubs.length - 1 ? ", " : ""}
                                            </span>
                                        ))}
                                </td>
                                {file.success ? (
                                    <Link
                                        to={`/session-stats/${file.sessionId}`}
                                        className="btn btn-primary table-btn"
                                    >
                                        View Session
                                    </Link>
                                ) : (
                                    "" // Render an empty string if the file import is not successful
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Go Back
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Import Anyway
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AddManualSession;
