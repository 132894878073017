import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Container, Col, Row } from "react-bootstrap";
import { clubDisplayOrder } from "./ClubDisplayOrder";
import { FiShare } from "react-icons/fi";

import ColumnSelectionModal from "./ColumnSelectionModal";

const ClubStatsTable = ({ shots, onCapture }) => {
  const [clubStats, setClubStats] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: "asc",
  });

  //elements for configuring ability to toggle on/off columns
  const [visibleColumns, setVisibleColumns] = useState({
    Club: true,
    Carry: true,
    "Carry SDV": true,
    "Total Distance": true,
    VLA: true,
    "VLA SDV": false,
    HLA: true,
    "HLA SDV": false,
    "Ball Speed": true,
    "Ball Speed SDV": false,
    Descent: false,
    "Descent SDV": false,
    Offline: true,
    "Offline SDV": true,
    PeakHeight: false,
    "PeakHeight SDV": false,
    BackSpin: false,
    "BackSpin SDV": false,
    SideSpin: false,
    "SideSpin SDV": false,
    "# Shots": true,
  });

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleColumnToggle = (column) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };
  const handleSort = (columnName) => {
    if (columnName === sortOrder.column) {
      // If the same column is clicked again, reverse the direction
      setSortOrder({
        ...sortOrder,
        direction: sortOrder.direction === "asc" ? "desc" : "asc",
      });
    } else {
      // If a different column is clicked, sort by that column in ascending order
      setSortOrder({ column: columnName, direction: "asc" });
    }
  };

  useEffect(() => {
    const getClubStats = () => {
      const clubs = new Set();
      shots.forEach((shot) => {
        clubs.add(shot.Club);
      });

      const clubStatsArr = Array.from(clubs).map((club) => {
        const clubShots = shots.filter((shot) => shot.Club === club);

        const carryValues = clubShots.map((shot) => shot.Carry);
        const carryAvg =
          carryValues.reduce((total, val) => total + val, 0) /
          carryValues.length;
        const carrySdv = calculateStandardDeviation(carryValues);

        const totalDistanceValues = clubShots.map((shot) => shot.TotalDistance);
        const totalDistanceAvg =
          totalDistanceValues.reduce((total, val) => total + val, 0) /
          totalDistanceValues.length;

        const vlaValues = clubShots.map((shot) => shot.VLA);
        const vlaAvg =
          vlaValues.reduce((total, val) => total + val, 0) / vlaValues.length;
        const vlaSdv = calculateStandardDeviation(vlaValues);

        const hlaValues = clubShots.map((shot) => shot.HLA);
        const hlaAvg =
          hlaValues.reduce((total, val) => total + val, 0) / hlaValues.length;
        const hlaSdv = calculateStandardDeviation(hlaValues);

        const decentValues = clubShots.map((shot) => shot.Decent);
        const decentAvg =
          decentValues.reduce((total, val) => total + val, 0) /
          decentValues.length;
        const decentSdv = calculateStandardDeviation(decentValues);

        const ballSpeedValues = clubShots.map((shot) => shot.BallSpeed);
        const ballSpeedAvg =
          ballSpeedValues.reduce((total, val) => total + val, 0) /
          ballSpeedValues.length;
        const ballSpeedSdv = calculateStandardDeviation(ballSpeedValues);

        const offlineValues = clubShots.map((shot) => shot.Offline);
        const offlineAvg =
          offlineValues.reduce((total, val) => total + val, 0) /
          offlineValues.length;
        const offlineSdv = calculateStandardDeviation(offlineValues);

        const backSpinValues = clubShots.map((shot) => shot.BackSpin);
        const backSpinAvg =
          backSpinValues.reduce((total, val) => total + val, 0) /
          backSpinValues.length;
        const backSpinSdv = calculateStandardDeviation(backSpinValues);

        const sideSpinValues = clubShots.map((shot) => shot.SideSpin);
        const sideSpinAvg =
          sideSpinValues.reduce((total, val) => total + val, 0) /
          sideSpinValues.length;
        const sideSpinSdv = calculateStandardDeviation(sideSpinValues);

        const peakHeightValues = clubShots.map((shot) => shot.PeakHeight);
        const peakHeightAvg =
          peakHeightValues.reduce((total, val) => total + val, 0) /
          peakHeightValues.length;
        const peakHeightSdv = calculateStandardDeviation(peakHeightValues);

        return {
          Club: club,
          Carry: carryAvg,
          "Carry SDV": carrySdv,
          "Total Distance": totalDistanceAvg,
          VLA: vlaAvg,
          "VLA SDV": vlaSdv,
          HLA: hlaAvg,
          "HLA SDV": hlaSdv,
          Descent: decentAvg,
          "Descent SDV": decentSdv,
          "Ball Speed": ballSpeedAvg,
          "Ball Speed SDV": ballSpeedSdv,
          Offline: offlineAvg,
          "Offline SDV": offlineSdv,
          PeakHeight: peakHeightAvg,
          "PeakHeight SDV": peakHeightSdv,
          BackSpin: backSpinAvg,
          "BackSpin SDV": backSpinSdv,
          SideSpin: sideSpinAvg,
          "SideSpin SDV": sideSpinSdv,
          "# Shots": clubShots.length,
        };
      });
      clubStatsArr.sort((a, b) =>
        clubDisplayOrder[a.Club] < clubDisplayOrder[b.Club]
          ? -1
          : clubDisplayOrder[a.Club] > clubDisplayOrder[b.Club]
            ? 1
            : 0
      );

      setClubStats(clubStatsArr);
    };

    getClubStats();
  }, [shots]);

  const formatValue = (value) => {
    if (typeof value !== "number") {
      return value;
    }
    const formatted = value.toFixed(1);
    return formatted === "NaN" ? "" : formatted;
  };

  const calculateStandardDeviation = (values) => {
    const n = values.length;
    const mean = values.reduce((sum, value) => sum + value, 0) / n;
    const variance =
      values.reduce((sum, value) => sum + (value - mean) ** 2, 0) / n;
    const standardDeviation = Math.sqrt(variance);
    return standardDeviation;
  };

  const sortedClubStats = clubStats.sort((a, b) => {
    if (sortOrder.column === "Club") {
      if (sortOrder.direction === "asc") {
        return clubDisplayOrder[a.Club] - clubDisplayOrder[b.Club];
      } else {
        return clubDisplayOrder[b.Club] - clubDisplayOrder[a.Club];
      }
    } else {
      if (sortOrder.direction === "asc") {
        return a[sortOrder.column] - b[sortOrder.column];
      } else {
        return b[sortOrder.column] - a[sortOrder.column];
      }
    }
  });

  const arrowStyle = (column) => ({
    visibility: sortOrder.column === column ? "visible" : "hidden",
  });

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <style>
        {`
        .scrollable-table {
          overflow-x: auto;
          overflow-y: auto;


        }
        
        /* Fixed first column */
        .scrollable-table tbody tr td:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 5;
          overflow: hidden;
        }
        
        .scrollable-table tbody tr td:first-child::before {
          content: "";
          position: absolute;
          top: 0;
          left: -1px;
          bottom: 0;
          width: 1px;
          background-color: white;
        }
        
        /* Fixed first column in the header */
        .scrollable-table thead th:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 15;
          overflow: hidden;
        }
        
        .scrollable-table thead th:first-child::before {
          content: "";
          position: absolute;
          top: 0;
          left: -1px;
          bottom: 0;
          width: 1px;
          background-color: white;
        }
        
        
`}
      </style>
      <div>
        <Row>
          <style>
            {`
          .edit-columns-btn {
            text-align: right;
          }
          .table th {
            white-space: nowrap;
          }
        `}
          </style>
          <div className="table-container">
            <Row>
              <div className="d-flex justify-content-between align-items-center px-1">

                <h3>Club Summary</h3>

                <Col>
                  <div className="edit-columns-btn">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleShow}
                    >
                      Edit Columns
                    </Button>
                  </div>
                </Col>
                <button onClick={onCapture} style={{ background: "none", border: "none", paddingLeft: "10px", marginBottom: "4px" }}>
                  <FiShare size={18} color="grey" />
                </button>
              </div>

            </Row>
            <ColumnSelectionModal
              show={showModal}
              handleClose={handleModalClose}
              visibleColumns={visibleColumns}
              onColumnToggle={handleColumnToggle}
            />
            {/* <Button variant="primary" onClick={() => setShowModal(true)}>
        Edit Columns
      </Button> */}
            <div className="scrollable-table">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {Object.keys(visibleColumns).map((column) => {
                      if (visibleColumns[column]) {
                        return (
                          <th
                            key={column}
                            className="thWithArrow"
                            onClick={() => handleSort(column)}
                          >
                            <span>
                              {column}
                              <span style={arrowStyle(column)}>
                                {sortOrder.column === column &&
                                  sortOrder.direction === "asc"
                                  ? "↑"
                                  : "↓"}
                              </span>
                            </span>
                          </th>
                        );
                      }
                      return null;
                    })}
                  </tr>
                </thead>

                <tbody>
                  {sortedClubStats.map((clubStat) => (
                    <tr key={clubStat.Club}>
                      {Object.keys(visibleColumns).map((column) => {
                        if (visibleColumns[column]) {
                          return (
                            <td key={`${clubStat.Club}-${column}`}>
                              {typeof clubStat[column] === "number"
                                ? clubStat[column].toFixed(1)
                                : clubStat[column]}
                            </td>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default ClubStatsTable;
