import { useState, useEffect } from "react";
import { clubDisplayOrder } from "./ClubDisplayOrder";

const ClubSelectionCheckboxes = ({
  clubs,
  selectedClubs,
  toggleClubSelection,
  sort,
}) => {
  const sortedClubs = clubs.sort((a, b) => {
    const orderA = clubDisplayOrder[a];
    const orderB = clubDisplayOrder[b];

    if (sort === "desc") {
      return orderA - orderB;
    } else {
      return orderB - orderA;
    }
  });

  const [allSelected, setAllSelected] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    const allClubsSelected = clubs.every((club) => selectedClubs[club]);
    const someClubsSelected = clubs.some((club) => selectedClubs[club]);

    setAllSelected(allClubsSelected);
    setIndeterminate(!allClubsSelected && someClubsSelected);
  }, [clubs, selectedClubs]);

  const handleAllCheckboxChange = () => {
    const newAllSelected = !allSelected;
    setAllSelected(newAllSelected);

    clubs.forEach((club) => {
      toggleClubSelection(club, newAllSelected);
    });
  };

  return (
    <div>
      <style>
        {`.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-row {
  display: flex;
}

.table-cell {
  flex: 1;
}
`}
      </style>

      <div className="table-row">
        {sortedClubs.map((club) => (
          <div className="table-cell" key={club}>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                checked={selectedClubs[club] || false} // Add fallback value
                onChange={() => toggleClubSelection(club)}
              />
              <label>{club}</label>
            </div>
          </div>
        ))}
        <div className="table-cell">
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={allSelected}
              onChange={handleAllCheckboxChange}
              ref={(input) => {
                if (input) {
                  input.indeterminate = indeterminate;
                }
              }}
            />
            <label>All</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubSelectionCheckboxes;
