import React, { useState } from "react";
import { useAppContext } from "../context/appContext";
import { Alert as BootstrapAlert } from "react-bootstrap";

const AlertDismissable = () => {
  const [show, setShow] = useState(true);

  const { alertType, alertText, clearAlertNow } = useAppContext();

  const handleDismiss = () => clearAlertNow();

  return show ? (
    <BootstrapAlert variant={alertType} onClose={handleDismiss} dismissible>
      {alertText}
    </BootstrapAlert>
  )
    : null;
};
export default AlertDismissable;
