import { useState } from "react";
import { Alert, FormRow, Logo } from "../components";
import { Alert as BootstrapAlert } from "react-bootstrap";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";

const initialState = {
  email: "",
};

const ForgotPassword = () => {
  const { requestPasswordReset } = useAppContext();
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState(initialState);
  const { isLoading, showAlert, displayAlert } = useAppContext();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { email } = values;
    if (!email) {
      displayAlert();
      return;
    }
    requestPasswordReset(email);
    setSubmitted(true);
  };

  return (
    <Wrapper className="full-page">
      <style>
        {`  
        .btn-block {
    background-color: #6366f1;
    color: #fff; // Add this line if you want the text color to be white
    text-decoration: none;
    display: inline-block;
  }

  .btn-block:hover {
    background-color:#5a5fd9; // Add a slightly darker color for hover effect
    color: #fff;
    text-decoration: none;
  }
  `}
      </style>
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>Forgot Password</h3>
        {submitted && (
          <BootstrapAlert variant="success">
            If an account exists, check your inbox for a link to reset your
            password.
          </BootstrapAlert>
        )}
        {showAlert && <Alert value="Please provide a valid email" />}
        {/* email input */}
        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />

        <button type="submit" className="btn btn-block" disabled={isLoading}>
          Send Password Reset Link
        </button>
      </form>
    </Wrapper>
  );
};

export default ForgotPassword;
