import "chartjs-adapter-date-fns";

const AggregateThreeLineData = (data, metric, dimension) => {
  const groupByDimension = (dateString) => {
    const date = new Date(dateString);
    switch (dimension) {
      case "days":
        return date.toISOString().split("T")[0];
      case "weeks":
        return `${date.getUTCFullYear()}-W${Math.ceil(
          (date - new Date(date.getUTCFullYear(), 0, 1)) / 86400000 / 7
        )}`;
      case "months":
        return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`;
      case "quarters":
        return `${date.getUTCFullYear()}-Q${Math.ceil(
          (date.getUTCMonth() + 1) / 3
        )}`;
      case "years":
        return date.getUTCFullYear().toString();
      default:
        throw new Error(`Invalid dimension: ${dimension}`);
    }
  };

  // console.log("data", data);

  const groupData = data?.reduce((acc, shot) => {
    const group = groupByDimension(shot.shotDate);
    if (!acc[group]) {
      acc[group] = {
        count: 0,
        sum: 0,
        sumOfSquares: 0,
      };
    }
    acc[group].count++;
    acc[group].sum += shot[metric];
    acc[group].sumOfSquares += shot[metric] * shot[metric];
    return acc;
  }, {});

  const calculateSD = (sum, sumOfSquares, count) => {
    const variance = (sumOfSquares - (sum * sum) / count) / count;
    return Math.sqrt(variance);
  };

  const chartData = {
    labels: [],
    datasets: [
      {
        label: `Average ${metric}`,
        data: [],
        borderColor: "#3366cc",
        backgroundColor: "#3366cc",
      },
      {
        label: "Avg +1 SD",
        data: [],
        borderColor: "#5574a6",
        backgroundColor: "#5574a6",
        borderDash: [10, 5],
      },
      {
        label: "Avg -1 SD",
        data: [],
        borderColor: "#5574a6",
        backgroundColor: "#5574a6",
        borderDash: [10, 5],
      },
    ],
  };

  const sortDateStrings = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  };

  if (!groupData) {
    return chartData;
  }

  const sortedGroups = Object.keys(groupData).sort(sortDateStrings);

  for (const group of sortedGroups) {
    // console.log("Inside for loop:", group);

    const { count, sum, sumOfSquares } = groupData[group];
    const average = sum / count;
    const sd = calculateSD(sum, sumOfSquares, count);

    chartData.labels.push(group);
    chartData.datasets[0].data.push({ x: group, y: average });
    chartData.datasets[1].data.push({ x: group, y: average + sd });
    chartData.datasets[2].data.push({ x: group, y: average - sd });
  }
  // console.log("Final chartData:", chartData);

  return chartData;
};

export default AggregateThreeLineData;
