import { useState } from "react";
import { Alert, FormRow, Logo } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Alert as BootstrapAlert } from "react-bootstrap";

const initialState = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const { resetPassword } = useAppContext();
  const [values, setValues] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const { showAlert, displayAlert, displayCustomAlert } = useAppContext();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = values;

    if (!password || !confirmPassword) {
      displayAlert();
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setShowErrorAlert(true);
      return;
    }

    const result = await resetPassword(token, password);
    if (result && result.success) {
      setShowSuccessAlert(true);
      setTimeout(() => {
        navigate("/register", { state: { isMember: true } });
      }, 2000);
    } else {
      const errorMessage =
        result.error.response?.data?.msg || "Error resetting password.";
      setErrorMessage(errorMessage);
      if (errorMessage.includes("Invalid or expired token")) {
        setServerError(true);
      }
      setShowErrorAlert(true);
    }
  };

  return (
    <Wrapper className="full-page">
      <style>
        {`  
        .btn-block {
          background-color: #6366f1;
          color: #fff; // Add this line if you want the text color to be white
          text-decoration: none;
          display: inline-block;
        }
      
        .btn-block:hover {
          background-color:#5a5fd9; // Add a slightly darker color for hover effect
          color: #fff;
          text-decoration: none;
        }
  `}
      </style>
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>Reset Password</h3>
        {showErrorAlert && (
          <BootstrapAlert
            variant="danger"
            onClose={() => setShowErrorAlert(false)}
            dismissible
          >
            {errorMessage}

            {serverError && (
              <BootstrapAlert.Link href="/forgot-password">
                {" "}
                Request new link
              </BootstrapAlert.Link>
            )}
          </BootstrapAlert>
        )}
        {showAlert && <Alert value="Please provide a valid input" />}
        {showSuccessAlert && (
        <BootstrapAlert
          variant="success"
          onClose={() => setShowSuccessAlert(false)}
          dismissible
        >
          Password reset successful!
        </BootstrapAlert>
        )}
        {/* Password input */}
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
          placeholder="New Password"
        />
        {/* Confirm Password input */}
        <FormRow
          type="password"
          name="confirmPassword"
          value={values.confirmPassword}
          handleChange={handleChange}
          placeholder="Confirm New Password"
        />
        <button type="submit" className="btn btn-block">
          Reset Password
        </button>
      </form>
    </Wrapper>
  );
};

export default ResetPassword;
