import React, { useContext, useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";


import { ShotDataContext } from "../context/shotDataContext";

import ellipsePlugin from "../utils/ellipsePlugin";
import drawCirclesPlugin from "./charts/drawCirclesPlugin";
// import { options } from "../utils/scatterPlotUtils";
import ShotScatterPlot from "./ShotScatterPlot";
import "./ShotScatterPlotWrapper.css";
import { useEllipseData } from "../hooks/useEllipseData";
// import { useShotData } from "../context/shotDataContext";


const ShotScatterPlotWrapper = ({
  shots,
  selectedClubs, // object with club names as keys and booleans as values
  sessionEllipseData, // only comes in for a session - not all stats page
  contextType,
  sessionId,
  allShotEllipseData,
  startDate,
  endDate,
  onCapture,
  qualityFilterState,
  includeTags,
  excludeTags
}) => {
  const [filteredShots, setFilteredShots] = useState([]);
  // const [clubsBeingFetched, setClubsBeingFetched] = useState(new Set());
  // console.log("inside SSPW with allShotEllipseData: ", allShotEllipseData)

  const chartRef = useRef(null);

  const { ellipseOptions, setEllipseOptions, filteredEllipseOptions, setFilteredEllipseOptions, extractEllipseOptions, ellipsesToUse, isLoading } =
    useEllipseData({ contextType, allShotEllipseData, sessionEllipseData, selectedClubs, shots, sessionId, startDate, endDate, qualityFilterState, includeTags, excludeTags });

  useEffect(() => {
    const chartInstance = chartRef.current?.chartInstance;
    if (chartInstance) {
      chartInstance.update();
    }
  }, [ellipseOptions]);

  useEffect(() => {
    const newFilteredShots = shots.filter((shot) => selectedClubs[shot.Club]);
    setFilteredShots(newFilteredShots);
  }, [shots, selectedClubs]);

  useEffect(() => {
    const chartInstance = chartRef.current?.chartInstance;
    if (chartInstance) {
      Chart.register(drawCirclesPlugin);
      chartInstance.update();
    }
  }, [chartRef]);

  //set options on load
  useEffect(() => {
    //if there is no ellipseData, then we are in an 'all shots' scenario, not specific to a session.
    //   so we use the allShotsEllipseData to set the ellipseOptions
    if (!sessionEllipseData) {
      // console.log('no ellipse data')
      if (!startDate && !endDate) {//if no dates, then we use the allShots data which will have come from the DB
        const allShotsEllipseOptions = extractEllipseOptions(allShotEllipseData, 'ellipseParameters');
        const filteredShotsEllipseOptions = extractEllipseOptions(allShotEllipseData, 'filteredEllipseParameters');

        // console.log("initial allShotEllipseData: ", allShotEllipseData)
        // console.log("allShotsEllipseOptions: ", allShotsEllipseOptions);

        setEllipseOptions(allShotsEllipseOptions);
        setFilteredEllipseOptions(filteredShotsEllipseOptions);
      }
      return;
    }

    // or we have ellipse Data and are in a given session view
    const newEllipseOptions = sessionEllipseData?.reduce((acc, ellipse) => {
      acc[ellipse.club] = ellipse.ellipseParameters;
      return acc;
    }, {});
    // console.log("newEllipseOptions: ", newEllipseOptions);
    setEllipseOptions(newEllipseOptions);
  }, [sessionEllipseData, allShotEllipseData, startDate, endDate]);

  return (
    <ShotScatterPlot shots={filteredShots} ellipseOptions={ellipsesToUse} onCapture={onCapture} className="scatterPlot" ellipsesAreLoading={isLoading} />
  );
};

export default React.memo(ShotScatterPlotWrapper, (prevProps, nextProps) => {
  return (
    prevProps.shots === nextProps.shots &&
    prevProps.selectedClubs === nextProps.selectedClubs &&
    prevProps.ellipseOptions === nextProps.ellipseOptions
  );
});

Chart.register(ellipsePlugin);
Chart.register(drawCirclesPlugin);

