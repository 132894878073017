import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/appContext";

const ProtectedRoute = ({ children }) => {
  //if no user, navigate user away, else show them where they are trying to get to
  const { user, userLoading } = useAppContext();
  if (userLoading) return <div />;

  if (user) {
    return children;
  } else {
    // console.log("no user: ");
    return <Navigate to="landing" />;
  }
  //if user return children
};
export default ProtectedRoute;
