import { useState, useEffect, useRef, useContext } from "react";
import { useAppContext } from "../context/appContext";
import { ShotDataContext } from "../context/shotDataContext";

function useSessions(user) {
    const {
        getAllSessions,
        deleteSession,
        deletedSessionId,
        setDeletedSessionId,
        sessions,
    } = useAppContext();
    const hasBeenCalled = useRef(false);
    const { sessionWasImported, setSessionWasImported, setRefreshData } = useContext(ShotDataContext);

    // Fetch all sessions for the user
    useEffect(() => {
        if (!hasBeenCalled.current && (sessions?.length === 0 || sessionWasImported)) {
            getAllSessions(user);
            hasBeenCalled.current = true;
            setSessionWasImported(false);
        }
    }, [getAllSessions, user, sessionWasImported]);

    // Handle the scenario after a session is deleted
    useEffect(() => {
        if (deletedSessionId) {
            setDeletedSessionId(null); // Reset the deletedSessionId state
            getAllSessions(user);
        }
    }, [deletedSessionId, setDeletedSessionId, sessions]);

    // Method to delete a session
    const handleDelete = async (sessionId) => {
        if (window.confirm("Are you sure you want to delete this session?")) {
            //get unique clubs for this session
            let clubs = sessions.filter(
                (session) => session._id === sessionId
            )[0].clubs;
            await deleteSession(sessionId, clubs);
            setRefreshData(prev => !prev);
        }
    };

    return {
        sessions,
        handleDelete,
        // You can also return any other session-related functions or state if needed
    };
}

export default useSessions;
