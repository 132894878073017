import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AggregateThreeLineData from "./AggregateThreeLineData";
import "./ThreeLineChart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false, // Add this line
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    title: {
      display: false,
      //   text: "Chart.js Line Chart",
    },
  },
};

const ThreeLineChart = ({ shotsData, metric, groupingDimension }) => {
  // console.log(shotsData, groupingDimension);
  // console.log(shotsData.count);
  let analyzedData = null;
  if (shotsData !== []) {
    analyzedData = AggregateThreeLineData(shotsData, metric, groupingDimension);
  }
  return (
    <div className="three-line-chart-container mt-5">
      <h4 className="chart-title">{metric} & Consistency Trend</h4>

      <Line options={options} data={analyzedData} />
    </div>
  );
};
export default ThreeLineChart;
