import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert, FormRow, Logo } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import { Logtail } from "@logtail/browser";
const logtail = new Logtail("mQPcdTx6pagK2hdLmyCbF8kz");
// global context and useNavigate later

//set up empty state
const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: false,
};

// if possible prefer local state
// global state

const Register = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isMemberFromLocation = location.state?.isMember ?? false;
  const [values, setValues] = useState({
    ...initialState,
    isMember: isMemberFromLocation,
  });

  const { user, isLoading, showAlert, displayAlert, setupUser } =
    useAppContext();

  // global context and useNavigate

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  //e = event
  const handleChange = (e) => {
    // console.log(e.target);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember } = values;
    if (!email || !password || (!name && !isMember)) {
      displayAlert();
      return;
    }
    const currentUser = { name, email, password };

    if (isMember) {
      setupUser({
        currentUser,
        endPoint: "login",
        alertText: "Login Successful. Redirecting...",
      });
    } else {
      setupUser({
        currentUser,
        endPoint: "register",
        alertText: "User Created. Redirecting...",
      });
    }
  };

  //bug - this is redirecting away ev
  useEffect(() => {
    if (user) {
      logtail.info(`user loggedIn: ${user._id}`);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [user, navigate]);

  return (
    <Wrapper className="full-page">
      <style>
        {`  
        .btn-block {
    background-color: #6366f1;
    color: #fff; // Add this line if you want the text color to be white
    text-decoration: none;
    display: inline-block;
  }

  .btn-block:hover {
    background-color:#5a5fd9; // Add a slightly darker color for hover effect
    color: #fff;
    text-decoration: none;
  }
  `}
      </style>
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>{values.isMember ? "Login" : "Sign Up"}</h3>
        {showAlert && <Alert value="helpme" />}
        {/* name input */}
        {!values.isMember && (
          <FormRow
            type="text"
            name="name"
            value={values.name}
            handleChange={handleChange}
          />
        )}
        {/* email input */}
        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />
        {/* password input */}
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
        />

        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {values.isMember ? "Login" : "Submit"}
        </button>
        <p>
          <button type="button" onClick={toggleMember} className="member-btn">
            {values.isMember
              ? "Not a member? Sign Up Now."
              : "Already a member? Login."}
          </button>
          <br />
          {!values.isMember ? null : (
            <span className="forgot-password-link">
              <a
                href="/forgot-password"
                style={{ color: "#bbb", textDecoration: "none" }}
              >
                Reset Password
              </a>
            </span>
          )}
        </p>
      </form>
    </Wrapper>
  );
};

export default Register;
