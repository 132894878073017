const clubDisplayOrder = {
  DR: 1,
  W3: 2,
  W7: 3,
  W9: 4,
  H2: 5,
  H3: 6,
  H4: 7,
  H5: 8,
  H6: 9,
  H7: 10,
  H8: 11,
  H9: 12,
  I1: 13,
  I2: 14,
  I3: 15,
  I4: 16,
  I5: 17,
  I6: 18,
  I7: 19,
  I8: 20,
  I9: 21,
  PW: 22,
  GW: 23,
  SW: 24,
  LW: 25,
  54: 26,
  58: 27,
  60: 28,
};

var colorsList = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];
export { clubDisplayOrder, colorsList };
