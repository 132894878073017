export const DISPLAY_ALERT = "SHOW_ALERT";
export const DISPLAY_CUSTOM_ALERT = "SHOW_CUSTOM_ALERT";

export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const ADD_SESSION_BEGIN = "ADD_SESSION_BEGIN";
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS";
export const ADD_SESSION_ERROR = "ADD_SESSION_ERROR";

export const DELETE_SESSION_BEGIN = "DELETE_SESSION_BEGIN";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_ERROR = "DELETE_SESSION_ERROR";

export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";

export const DELETE_SHOT_SUCCESS = "DELETE_SHOT_SUCCESS";
export const DELETE_SHOT_ERROR = "DELETE_SHOT_ERROR";
export const DELETE_SHOT_BEGIN = "DELETE_SHOT_BEGIN";

export const GET_CURRENT_USER_BEGIN = "GET_CURRENT_USER_BEGIN";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const ADD_TAG_TO_SESSION_ERROR = "ADD_TAG_TO_SESSION_ERROR";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";

export const UPDATE_SHOT_ERROR = "UPDATE_SHOT_ERROR";

export const DELETE_ELLIPSES_SUCCESS = "DELETE_ELLIPSES_SUCCESS";
export const DELETE_ELLIPSES_ERROR = "DELETE_ELLIPSES_ERROR";

export const Steve = "steve";
