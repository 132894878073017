import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { clubDisplayOrder, colorsList } from "./ClubDisplayOrder";

const BallFlightChart = ({ shots }) => {
  const [chartData, setChartData] = useState([]);

  const chartRef = useRef(null);

  useEffect(() => {
    processShots(shots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shots]);

  const processShots = (shots) => {
    const clubDatasets = {};

    shots.forEach((shot) => {
      const trajectoryPoints = shot.calculatedTrajectoryData;
      const club = shot.Club;

      if (!clubDatasets[club]) {
        clubDatasets[club] = {
          label: club,
          data: [],
          borderColor: getColor(club),
          fill: false,
        };
      }

      clubDatasets[club].data.push(
        ...trajectoryPoints.map((point) => ({
          x: point.y,
          y: point.z,
          _id: shot._id, // Store the _id as a property on the data point
        }))
      );
    });

    const data = Object.values(clubDatasets);
    setChartData(data);
  };

  const getColor = (club) => {
    const clubIndex = clubDisplayOrder[club] - 1; // Subtract 1 to match the 0-based index of colorsList

    return colorsList[clubIndex] || "#000000"; // Default color if no mapping exists
  };
  //TODOs:
  //2 remove x axis labels? or otherwise note deltas vs. GSPro
  return (
    <div>
      <Line
        ref={chartRef}
        data={{
          datasets: chartData,
        }}
        options={{
          scales: {
            x: {
              type: "linear",
              display: true,
              title: {
                display: true,
                text: "Distance (yds)",
              },
            },
            y: {
              type: "linear",
              min: 0,
              display: true,
              title: {
                display: true,
                text: "Height (yds)",
              },
            },
          },
          pointStyle: false,
          plugins: {
            tooltip: {
              callbacks: {
                title: (context) => {
                  return context[0].dataset.label;
                },
                label: (context) => {
                  const dataIndex = context.dataIndex;
                  const shotId = context.dataset.data[dataIndex]._id;

                  const shot = shots.find((s) => s._id === shotId);

                  return [
                    `Carry: ${shot.Carry}`,
                    `Offline: ${shot.Offline}`,
                    `VLA: ${shot.VLA}`,
                  ];
                },
              },
            },
          },
        }}
      />
      {/* <CustomTooltip tooltipData={tooltipData} /> */}
    </div>
  );
};

export default BallFlightChart;
