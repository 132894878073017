import React, { useReducer, useState, useContext, useEffect } from "react";
import reducer from "./reducer";
import {
  CLEAR_ALERT,
  DISPLAY_ALERT,
  DISPLAY_CUSTOM_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_ERROR,
  SETUP_USER_SUCCESS,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  ADD_SESSION_BEGIN,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_ERROR,
  UPDATE_SHOT_ERROR,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  DELETE_SESSION_BEGIN,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
  DELETE_SHOT_BEGIN,
  DELETE_SHOT_SUCCESS,
  DELETE_SHOT_ERROR,
  GET_SESSIONS_SUCCESS,
  DELETE_TAG_SUCCESS,
  ADD_TAG_TO_SESSION_ERROR,
  DELETE_ELLIPSES_SUCCESS,
  DELETE_ELLIPSES_ERROR
} from "./actions";
import axios from "axios";

import { Logtail } from "@logtail/browser";
const logtail = new Logtail("mQPcdTx6pagK2hdLmyCbF8kz");

const getInitialState = () => {
  // const token = localStorage.getItem("token");
  // const user = localStorage.getItem("user");

  return {
    userLoading: true,
    isLoading: false,
    showAlert: false,
    showResult: false,
    alertText: "",
    alertType: "",
    user: null,
    // user: user ? JSON.parse(user) : null,
    // token: localStorage.getItem("token"),
    userLocation: "",
    showSidebar: false,
    deletedSessionId: null,
    deletedShotId: null,
    sessions: [],
  };
};

const initialState = getInitialState();

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [importedData, setImportedData] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [deletedSessionId, setDeletedSessionId] = useState(null);
  const [deletedShotId, setDeletedShotId] = useState(null);
  const [user, setUser] = useState(null);
  const [tags, setTags] = useState(null);


  //axios - setting a 'authFetch' that I can use any time I need to pass the bearer token for a secure request
  // and I can use regular axios.post... when I do NOT need to be logged in
  const authFetch = axios.create({
    baseURL: "/api/v1",
  });

  //request

  //response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log(error.response);
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const displayCustomAlert = (text) => {
    dispatch({
      type: DISPLAY_CUSTOM_ALERT,
      payload: {
        msg: text,
      },
    });
    clearAlert();
  };
  const displayCustomAlertNoClear = (text) => {
    dispatch({
      type: DISPLAY_CUSTOM_ALERT,
      payload: {
        msg: text,
      },
    });
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
      });
    }, 5000);
  };

  const clearAlertNow = () => {
    dispatch({
      type: CLEAR_ALERT,
    });
  };

  // const addUserToLocalStorage = ({ user, token, location }) => {
  //   localStorage.setItem("user", JSON.stringify(user));
  //   localStorage.setItem("token", token);
  // };

  // const removeUserFromLocalStorage = () => {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("token");
  // };

  const setupUser = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const response = await axios.post(
        `/api/v1/auth/${endPoint}`,
        currentUser
      );
      // console.log(response);
      const { user, token } = response.data;
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: {
          user,
          token,
          alertText,
        },
      });
      setUser(user);
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateUser = async (updatedUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch(`/auth/updateUser`, updatedUser);
      const { user, token } = data;
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: {
          user,
          token,
          alertText: "Changes Saved ",
        },
      });
      setUser(user);
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const getCurrentUser = async () => {
    dispatch({ type: GET_CURRENT_USER_BEGIN });
    try {
      const { data } = await authFetch("/auth/getCurrentUser");
      const { user, location } = data;

      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: { user, location },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      logoutUser();
    }
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const logoutUser = async () => {
    await authFetch.get("/auth/logout");
    dispatch({ type: LOGOUT_USER });
  };

  const createSession = async (files, filetype) => {
    dispatch({ type: ADD_SESSION_BEGIN });
    try {
      const formData = new FormData();
      formData.append("fileType", filetype);
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      const { data } = await authFetch.post(`/sessions/upload`, formData);
      setImportedData(data);
      dispatch({
        type: ADD_SESSION_SUCCESS,
        // payload: { shotCount: data.shots.length },
      });
      return { success: true, data };
    } catch (error) {
      dispatch({
        type: ADD_SESSION_ERROR,
        payload: { msg: error.response.data },
      });
      console.log("error creating session: ", error);
      return { success: false, msg: error.response.data.msg };
    }
  };


  const createCustomSession = async (file, headerMappings, sessionDate) => {
    dispatch({ type: ADD_SESSION_BEGIN });
    try {

      //create payload to pass inputs to the backend using hte /uploadCustom route
      const formData = new FormData();
      formData.append("file", file);
      formData.append("headerMappings", JSON.stringify(headerMappings));
      formData.append("sessionDate", sessionDate);
      formData.append("fileType", "custom");


      const { data } = await authFetch.post(`/sessions/upload`, formData);
      setImportedData(data);
      dispatch({
        type: ADD_SESSION_SUCCESS
      });
      return { success: true, data };
    }
    catch (error) {
      dispatch({
        type: ADD_SESSION_ERROR,
        payload: { msg: error.response.data },
      });
      console.log("error creating custom session: ", error.response.data);
      return { success: false, msg: error.response.data };

    }
  }

  const getAllSessions = async (currentUser) => {
    // console.log(
    //   "Calling getAllSessions in appContext with currentUser: ",
    //   currentUser
    // );

    try {
      const response = await authFetch.get(`/sessions`, currentUser); // Call the API function
      setSessions(response.data.sessions); // Update the sessions state with the fetched data
      // console.error(" In app context and got all sessions:", response.data.sessions);

      return response.data.sessions;
    } catch (error) {
      // console.error(" In app context with error fetching all sessions:", error);
      // Handle error accordingly
    }
  };

  const getHeadersFromFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await authFetch.post("/sessions/getHeaders", formData);
      return response.data; // instead of response.data.headers
    } catch (error) {
      console.error("Error getting headers from file:", error);
      throw new Error("Failed to get headers from file.");
    }
  };


  const getSessionById = async (sessionId, currentUser) => {
    // console.log("in get session by id with sessionId: " + sessionId);
    try {
      const response = await authFetch.get(
        `/sessions/${sessionId}`,
        currentUser
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching session", error);
    }
  };

  const getMostRecentSession = async (user) => {
    // console.log("in get most recent session");

    try {
      const response = await authFetch.get("/sessions/most-recent", user);
      return response.data;
    } catch (error) {
      console.error("Error fetching most recent session", error);
    }
  };

  //TODO - need to add dispatch actions for start and end and error so the FE can handle it
  const fetchEllipseDataForShotsAndSession = async ({
    club,
    startDate,
    endDate,
    sessionId,
    includeTags,
    excludeTags
  }
  ) => {
    // console.log(
    //   "in app context fetchEllipseDataForClubAndSession with sessionId: ",
    //   sessionId
    // );
    try {
      const response = await authFetch.post(
        "/ellipse/calculateEllipseParameters",
        {
          club,
          startDate,
          endDate,
          sessionId,
          includeTags,
          excludeTags
        }
      );
      // console.log(
      //   "back in app context fetchEllipseDataForClubAndSession with response: ",
      //   response.data
      // );
      return response.data;
    } catch (error) {
      console.error("Error calculating ellipse data", error);
    }
  };

  //Removing here as we handle it in the back end when needed now
  // const deleteEllipses = async (ellipseIds) => {
  //   try {
  //     const { data } = await authFetch.post('/ellipse/deleteEllipses', {
  //       ids: ellipseIds
  //     });

  //     if (data.success && data.deletedCount > 0) {
  //       dispatch({
  //         type: DELETE_ELLIPSES_SUCCESS,
  //         payload: { ellipseIds: data.deletedIds }
  //       });

  //     } else {
  //       logtail.error(data.message);
  //     }

  //   } catch (error) {
  //     logtail.error(error);
  //   }
  //   clearAlert();
  // };



  const deleteSession = async (sessionId, clubs) => {
    try {
      const { data } = await authFetch.delete(`/sessions/${sessionId}`, {
        data: { clubs: clubs },
      });
      // console.log(data);
      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: { sessionId },
      });
      setDeletedSessionId(sessionId);
    } catch (error) {
      dispatch({
        type: DELETE_SESSION_ERROR,
        payload: { sessionId, msg: error.response.data.msg },
      });
      console.log(error);
    }
    clearAlert();
  };

  const deleteShotById = async (shotId, sessionId, currentUser) => {
    // console.log("inside deleteShotById");
    dispatch({ type: DELETE_SHOT_BEGIN });

    try {
      const { data } = await authFetch.delete(
        `/shots/${shotId}`,
        shotId,
        sessionId,
        currentUser
      );
      dispatch({
        type: DELETE_SHOT_SUCCESS,
        payload: { shotId },
      });
      clearAlert();
      setDeletedShotId(shotId);
      return { success: true, data: data.deletedShot };
    } catch (error) {
      dispatch({
        type: DELETE_SHOT_ERROR,
        payload: { shotId, msg: error.response.data.msg },
      });
      return { success: false };
    }
  };


  const updateShotClub = async (shotId, club) => {
    // console.log("inside updateShotClub");
    // dispatch({ type: UPDATE_SHOT_BEGIN });

    try {
      const { data } = await authFetch.put(
        `/shots/${shotId}`,
        { club }
      );
      // dispatch({
      //   type: UPDATE_SHOT_SUCCESS,
      //   payload: { shotId },
      // });

      return { success: true };
    } catch (error) {
      dispatch({
        type: UPDATE_SHOT_ERROR,
        payload: { shotId, msg: error.response.data.msg },
      });
      return { success: false };
    }
  };




  const requestPasswordReset = async (email) => {
    try {
      const response = await axios.post(`/api/v1/auth/request-password-reset`, {
        email,
      });
      // Handle the response, e.g., show a success message
      return { success: true, data: response.data };
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error requesting password reset:", error);
      return { success: false, error };
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await axios.post(`/api/v1/auth/reset-password`, {
        token,
        newPassword,
      });
      // Handle the response, e.g., show a success message
      return { success: true, data: response.data };
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error resetting password:", error);
      return { success: false, error };
    }
  };

  const markTooltipAsSeen = async () => {
    try {
      const response = await authFetch.patch(`/auth/seenTooltip`);
      // Handle the response, e.g., show a success message
      setUser(response.data.user);
      return { success: true };
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error marking tooltip as seen:", error);
      return { success: false, error };
    }
  };

  ///////////// TAGS //////////////
  const getUserTags = async (user) => {
    try {
      const response = await authFetch.get(`/user/tags`);
      if (response.status === 200) {
        const userTags = response.data;
        // console.log("userTags: ", userTags)
        setTags(userTags);
      }
    } catch (error) {
      logtail.error(error);
      return { success: false, message: error.message || 'An error occurred while fetching tags.' };
    }
  };


  const addTagToUser = async (tagName) => {
    try {
      const response = await authFetch.put(
        `/user/tags`,
        { name: tagName }
      );

      if (response.status === 200 || response.status === 201) {
        const newTag = response.data;
        setTags(previousTags => [...previousTags, newTag]);
        return newTag;
      } else {
        throw new Error("Unable to add tag.");
      }
    } catch (error) {
      console.error('Error adding tag: ', error);
      throw error;
    }
  }

  const deleteTagFromUser = async (tagId) => {
    try {
      const response = await authFetch.delete(`/user/tags/${tagId}`);

      if (response.status === 200 || response.status === 201) {
        const updatedTags = response.data;
        setTags(updatedTags); // This line updates the global tags state

        //dispatch DELETE_TAG_SUCCESS

        dispatch({
          type: DELETE_TAG_SUCCESS,
        });
        clearAlert();



        return updatedTags; // Return the updated tags
      } else {
        displayCustomAlertNoClear("The tag could not be deleted, please refresh and try again.");
        throw new Error("Unable to delete tag.");
      }
    } catch (error) {
      displayCustomAlertNoClear("The tag could not be deleted, please refresh and try again.");
      throw error;
    }
  };

  const getSessionCountForTag = async (tagId) => {
    // router.route("/sessionCount/:tagId").get(authenticateUser, getSessionCountForTag);

    console.log("in here with tagId: " + tagId)
    try {
      const response = await authFetch.get(`/sessions/sessionCount/${tagId}`);
      if (response.status === 200) {
        const sessionCount = response.data.count;
        return sessionCount;
      }
    } catch (error) {
      logtail.error(error);
      return { success: false, message: error.message || 'An error occurred while fetching tags.' };
    }
  };



  const addTagToSession = async (tagId, sessionId) => {
    // console.log("inside addTagToSession with tagId: ", tagId, " and sessionId: ", sessionId);

    if (!tagId) {

      dispatch({
        type: DISPLAY_CUSTOM_ALERT,
        payload: {
          msg: "The tag could not be added to session, please try again.",
        },
      });

      // clearAlert();

      throw new Error("Invalid tag ID provided. Tag not found in user's tags.");
    }

    try {
      const response = await authFetch.put(
        `/sessions/${sessionId}/tags`,
        { tagId: tagId }
      );

      if (response.status === 200 || response.status === 201) {
        // console.log('Tag successfully added to the session');
        // Update the local sessions state
        setSessions(prevSessions => {
          const updatedSessions = [...prevSessions];
          const sessionIndex = updatedSessions.findIndex(session => session._id === sessionId);
          if (sessionIndex !== -1) {
            if (!updatedSessions[sessionIndex].tags) {
              updatedSessions[sessionIndex].tags = [];
            }
            updatedSessions[sessionIndex].tags.push(tagId);
          }
          return updatedSessions;
        });

      } else {
        throw new Error("Unable to add tag to session.");
      }
    } catch (error) {
      console.error('Error adding tag to session: ', error);
      dispatch({
        type: ADD_TAG_TO_SESSION_ERROR,
        payload: { msg: error.message || "The tag could not be added to session, please try again." },
      });
      throw error;
    }
  }


  const removeTagFromSession = async (tagId, sessionId) => {
    // console.log("inside removeTagFromSession")
    if (!tagId || !sessionId) {

      dispatch({
        type: DISPLAY_CUSTOM_ALERT,
        payload: {
          msg: "The tag could not be added to session, please try again.",
        },
      });

      // clearAlert();

      throw new Error("Invalid tag ID provided. Tag not found in user's tags.");
    }
    try {
      // console.log("inside try block with tagId: ", tagId, " and sessionId: ", sessionId)

      const response = await authFetch.delete(`/sessions/${sessionId}/tags`, {
        data: { tag: tagId },
      });

      if (response.status === 200 || response.status === 201) {
        // console.log('Tag successfully removed from session');
        setSessions(prevSessions => {
          const updatedSessions = [...prevSessions];
          const sessionIndex = updatedSessions.findIndex(session => session._id === sessionId);
          if (sessionIndex !== -1) {
            const tagIndex = updatedSessions[sessionIndex].tags?.findIndex(tid => tid === tagId);
            if (tagIndex !== -1) {
              updatedSessions[sessionIndex].tags.splice(tagIndex, 1);
            }
          }
          return updatedSessions;
        });
      } else {
        throw new Error("Unable to remove tag from session.");
      }
    } catch (error) {
      logtail.error('Error removing tag from session: ', error);
      dispatch({
        type: ADD_TAG_TO_SESSION_ERROR,
        payload: { msg: error.message || "The tag could not be removed from the session, please try again." },
      });
      throw error;
    }
  }













  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        displayCustomAlert,
        displayCustomAlertNoClear,
        clearAlertNow,
        setupUser,
        toggleSidebar,
        logoutUser,
        updateUser,
        importedData,
        sessions,
        getMostRecentSession,
        getSessionById,
        deletedSessionId,
        setDeletedSessionId,
        getHeadersFromFile,
        createSession,
        createCustomSession,
        getAllSessions,
        deleteSession,
        deleteShotById,
        updateShotClub,
        data: state.data,
        requestPasswordReset,
        resetPassword,
        markTooltipAsSeen,
        fetchEllipseDataForShotsAndSession,
        // deleteEllipses,

        tags,
        getUserTags,
        addTagToUser,
        deleteTagFromUser,
        addTagToSession,
        removeTagFromSession,
        getSessionCountForTag
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
