import Dropdown from "react-bootstrap/Dropdown";
import { format } from "date-fns";

const CustomDropdown = ({ session, allSessions, handleSessionChange }) => {
  return (
    <>
      {session && (
        <Dropdown onSelect={handleSessionChange}>
          <Dropdown.Toggle variant="outline-secondary" id="sessionSelect">
            {format(new Date(session.sessionDate), "dd MMM yy")} -{" "}
            {session.numberOfShots} shots - {session.clubs.join(", ")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {allSessions.map((s) => (
              <Dropdown.Item
                key={s._id}
                eventKey={s._id}
                style={{
                  color: s._id === session._id ? "#2cb1bc" : "inherit",
                }}
              >
                {format(new Date(s.sessionDate), "dd MMM yy")} -{" "}
                {s.numberOfShots} shots - {s.clubs.join(", ")}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};
export default CustomDropdown;
