import {
  MdCloudUpload,
  MdGolfCourse,
  MdOutlineAutoGraph,
  // MdOutlinePerson,
  MdOutlineViewList,
  MdQueryStats,
} from "react-icons/md";

import { GoGear } from "react-icons/go";

const links = [
  {
    id: 1,
    text: "all stats",
    path: "/",
    icon: <MdOutlineAutoGraph />,
    intro: "Easily see all of your shot stats right here.",
    position: "right",
  },
  {
    id: 2,
    text: "session stats",
    path: "session-stats",
    icon: <MdQueryStats />,
    intro: "Analyze a single session in more detail.",
    position: "right",
  },
  {
    id: 3,
    text: "all sessions",
    path: "all-sessions",
    icon: <MdOutlineViewList />,
    intro: "See the history of all your sim sessions.",
    position: "right",
  },
  {
    id: 6,
    text: "add session",
    path: "add-session",
    icon: <MdCloudUpload />,
    intro: "Get started here by importing a practice session from GS Pro or your Launch Monitor of Choice.",
    position: "right",
  },
  {
    id: 4,
    text: "club analysis",
    path: "club-analysis",
    icon: <MdGolfCourse />,
    intro: "Drill down into individual club metrics.",
    position: "right",
  },
  {
    id: 5,
    text: "settings",
    path: "settings",
    icon: <GoGear />,
    intro: "Update your settings.",
    position: "right",
  },
];

export default links;
