import {
  // Landing,
  Error,
  Register,
  ProtectedRoute,
  ForgotPassword,
  ResetPassword,
  Landing2
} from "./pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AddSession,
  AddManualSession,
  AllSessions,
  SessionStats,
  Settings,
  SharedLayout,
  Stats,
  ClubAnalysis,
} from "./pages/dashboard";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* first section is nesting 4 pages within a shared layout (side navbar) 
        in this case, the logged in view*/}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          {/* this 'index' value sets the selected page to be the one show at the exact matching path 
          - otherwise the only thing that would show at / would be the shared nav component */}
          <Route index element={<Stats />} />
          <Route path="all-sessions" element={<AllSessions />} />
          <Route path="/session-stats/:sessionId?" element={<SessionStats />} />
          <Route path="club-analysis" element={<ClubAnalysis />} />

          <Route path="add-session" element={<AddSession />} />
          <Route path="add-custom-session" element={<AddManualSession />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        {/* this second section is then individual pages that don't have shared nav
         - in this case - the logged out view*/}
        <Route path="/register" element={<Register />} />
        <Route path="/landing" element={<Landing2 />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
