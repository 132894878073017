import React, { memo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ColumnSelectionModal = memo(
  ({ show, handleClose, visibleColumns, onColumnToggle }) => {
    const splitCamelCase = (str) => {
      return str.replace(/([a-z])([A-Z])/g, "$1 $2");
    };
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Columns</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(visibleColumns).map((column) => (
            <div key={column} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={visibleColumns[column]}
                onChange={() => onColumnToggle(column)}
                id={`column-checkbox-${column}`}
              />
              <label
                className="form-check-label"
                htmlFor={`column-checkbox-${column}`}
              >
                {splitCamelCase(column)}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ColumnSelectionModal;
