import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { clubDisplayOrder } from "./ClubDisplayOrder";

const ClubSelectionDropdown = ({ clubs, onSelectClub }) => {
  const [selectedClub, setSelectedClub] = useState(null);

  const handleSelect = (club) => {
    setSelectedClub(club);
    onSelectClub(club);
  };

  const clubOptions = clubs
    .sort((a, b) => clubDisplayOrder[a] - clubDisplayOrder[b])
    .map((club) => (
      <Dropdown.Item key={club} eventKey={club}>
        {club}
      </Dropdown.Item>
    ));

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle variant="secondary" id="club-selection-dropdown">
        {selectedClub || "Select a club..."}
      </Dropdown.Toggle>

      <Dropdown.Menu>{clubOptions}</Dropdown.Menu>
    </Dropdown>
  );
};

export default ClubSelectionDropdown;
