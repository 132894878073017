//component to get all shot data for a given user and store it for use by multiple components on a page

import { useState, useEffect } from "react";
import ShotDataContext from "../context/shotDataContext";
import axios from "axios";

const ShotDataProvider = ({ children }) => {
  const [shotData, setShotData] = useState([]);
  const [sessionWasImported, setSessionWasImported] = useState(false);
  const [allShotEllipseData, setAllShotEllipseData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  //TODO - fetch all ellipse data

  const authFetch = axios.create({
    baseURL: "/api/v1",
  });

  useEffect(() => {
    const fetchShotData = async (currentUser) => {
      const { data } = await authFetch.get("/shots", currentUser);
      setShotData(data);
    };

    fetchShotData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  //TODO - add a call to calculate them if we don't have them
  //  though only needed for first login post launch so may want to handle differently
  //  or not at all as on next import or session delete, they'll get
  useEffect(() => {
    const fetchAllShotEllipseData = async (currentUser) => {
      const { data } = await authFetch.get(
        "/ellipse/allShotsEllipses",
        currentUser
      );
      // console.log("data", data);

      //TODO - if data is empty, trigger the calculation
      // console.log('allshot ellipse data on shot data providere: ', data)
      setAllShotEllipseData(data);
    };

    fetchAllShotEllipseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAverageCarryPerClub = (shots) => {
    const carryByClub = {};
    shots.forEach((shot) => {
      const { club, carry } = shot;
      if (!carryByClub[club]) {
        carryByClub[club] = { totalCarry: carry, count: 1 };
      } else {
        carryByClub[club].totalCarry += carry;
        carryByClub[club].count++;
      }
    });

    const averageCarryByClub = {};
    Object.keys(carryByClub).forEach((club) => {
      averageCarryByClub[club] =
        carryByClub[club].totalCarry / carryByClub[club].count;
    });
    return averageCarryByClub;
  };

  const removeShot = (shotId) => {
    const shotToRemove = shotData.find(shot => shot._id === shotId);
    if (shotToRemove) {
      const { Club } = shotToRemove;

      // Remove the shot from shotData
      setShotData(prevShotData => prevShotData.filter(shot => shot._id !== shotId));

      // Remove the ellipse data for the specific club
      setAllShotEllipseData(prevAllShotEllipseData =>
        prevAllShotEllipseData.filter(ellipse => ellipse.club !== Club)
      );
    }
  };

  const updateShot = (updatedShot) => {
    console.log("updating shot in context with: ", updatedShot);
    setShotData(prevShotData =>
      prevShotData.map(shot => shot._id === updatedShot._id ? updatedShot : shot)
    );
  };





  return (
    //TODO return ellipse data
    <ShotDataContext.Provider
      value={{ shotData, allShotEllipseData, setAllShotEllipseData, removeShot, updateShot, getAverageCarryPerClub, setRefreshData, setSessionWasImported, sessionWasImported }}
    >
      {children}
    </ShotDataContext.Provider>
  );
};

export default ShotDataProvider;
