import { useContext, useState, useEffect, useMemo, useRef } from "react";
// import ShotDataProvider from "../../components/ShotDataProvider";
import { ShotDataContext } from "../../context/shotDataContext";
import ClubStatsTable from "../../components/ClubStatsTable";
import { Tooltip } from "react-tooltip";
import { FiShare } from "react-icons/fi";
import Select from 'react-select';
import './Stats.css'

import WithCapture from "../../components/charts/withCapture";
import {
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip as ReTooltip,
  // Stack,
} from "react-bootstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import {
  BagMappingBoxPlot,
  ClubCarryChart,
  DateClubAnalysisTable,
  MetricDropdown,
  ShotScatterPlotWrapper,
} from "../../components/";
import ClubSelectionCheckboxes from "../../components/ClubSelectionCheckboxes.js";
import { FaQuestionCircle } from "react-icons/fa";
// import ClubStatsCalculator from "../../components/ClubStatsCalculator";
import { metricNameMapping } from "../../components/MetricDropdown";
import { useAppContext } from "../../context/appContext";
import ContextTypes from "../../context/contextTypes.js";

const CapturableShotScatterPlot = WithCapture(ShotScatterPlotWrapper);
const CapturableBagMappingBoxPlot = WithCapture(BagMappingBoxPlot);
const CapturableClubCarryChart = WithCapture(ClubCarryChart);
const CapturableClubStatsTable = WithCapture(ClubStatsTable);

const CombinedMetricsComponent = ({ metric1, metric2, handleMetric1Change, handleMetric2Change, handleTimeGroupingChange, filteredShots, timeGrouping, onCapture }) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center px-1">

        <h3>
          {metric1} and {metric2} by Club
        </h3>
        <button onClick={onCapture} style={{ background: "none", border: "none", paddingLeft: "10px", marginBottom: "4px" }}>
          <FiShare size={18} color="grey" />
        </button>
      </div>
      <div className="mt-3">
        <MetricDropdown
          metric1={metric1}
          metric2={metric2}
          handleMetric1Change={handleMetric1Change}
          handleMetric2Change={handleMetric2Change}
          handleTimeGroupingChange={handleTimeGroupingChange}
        />
      </div>
      <div className="mt-3">
        <DateClubAnalysisTable
          shots={filteredShots}
          metric1={metric1}
          metric2={metric2}
          timeGrouping={timeGrouping}
        />
      </div>
    </div>
  )
}

const CapturableCombinedMetricsComponent = WithCapture(CombinedMetricsComponent);

const useClubSelection = (clubs) => {
  const [selectedClubs, setSelectedClubs] = useState({});

  useEffect(() => {
    const initialSelectedClubs = {};
    clubs.forEach((club) => {
      initialSelectedClubs[club] = true;
    });
    setSelectedClubs(initialSelectedClubs);
  }, [clubs]);

  const toggleClubSelection = (club, newState) => {
    setSelectedClubs((prevSelectedClubs) => ({
      ...prevSelectedClubs,
      [club]: newState === undefined ? !prevSelectedClubs[club] : newState,
    }));
  };

  return [selectedClubs, toggleClubSelection];
};

const Stats = () => {
  const { user } = useAppContext();

  return (
    // <ShotDataProvider>
    <CapturableStatsContent
      targetId="full-page"
      username={user.useSgtNameInExport ? user.sgtName : user.name}
    />
    // </ShotDataProvider>
  );
};





const StatsContent = ({ onCapture, username }) => {
  const { user, sessions, getAllSessions, tags, getUserTags } = useAppContext();
  const [metric1, setMetric1] = useState("Carry");
  const [metric2, setMetric2] = useState("Carry SDV");
  const [timeGrouping, setTimeGrouping] = useState("date");

  const [includeTags, setIncludeTags] = useState([]);
  const [excludeTags, setExcludeTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    if (user && !tags) {
      getUserTags(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (tags) {
      setTagOptions(tags
        .map(tag => ({ value: tag.name, label: tag.name, id: tag._id }))
        .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [tags]);

  // Function to handle changes in the "Include" selector
  const handleIncludeTagsChange = (newValue) => {
    // console.log("handleIncludeTagsChange", newValue)
    setIncludeTags(newValue);
  };

  // Function to handle changes in the "Exclude" selector
  const handleExcludeTagsChange = (newValue) => {
    setExcludeTags(newValue);
  };


  //when we update the tags, we need to filter the filteredShots further based on the tags
  //note that each shot has a session and each session can have tags so we need to loo at the session tags for each session in sessions (session.tags), which has an array of tagIds
  //which map to tags.



  // Example handleMetric1Change function:
  const handleMetric1Change = (selectedMetric) => {
    setMetric1(selectedMetric);
  };
  // Example handleMetric2Change function:

  const handleMetric2Change = (selectedMetric) => {
    setMetric2(selectedMetric);
  };
  const handleTimeGroupingChange = (eventKey) => {
    setTimeGrouping(eventKey);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [value, onChange] = useState([null, null]);

  useEffect(() => {
    if (!value) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(value[0]);
    setEndDate(value[1]);
  }, [value]);
  const hasBeenCalled = useRef(false);

  // add a use effect to call getAllSessions if sessions is null
  useEffect(() => {
    if (!hasBeenCalled.current && (sessions?.length === 0)) { //&& sessions?.sessions?.length === 0
      getAllSessions(user);
      hasBeenCalled.current = true;
    }
  }, [getAllSessions]);



  const [warningMessage, setWarningMessage] = useState("");

  const { shotData, allShotEllipseData } = useContext(ShotDataContext);

  const getUniqueClubs = (shotData) => {
    const clubsSet = new Set(shotData.map((shot) => shot.Club));
    return Array.from(clubsSet);
  };

  const uniqueClubs = useMemo(() => getUniqueClubs(shotData), [shotData]);

  const [selectedClubs, toggleClubSelection] = useClubSelection(
    shotData ? uniqueClubs : []
  );

  const filteredShots = useMemo(() => {
    if (!shotData || !sessions) {
      return [];
    }

    return shotData.filter((shot) => {
      const shotDate = new Date(shot.shotDate);
      const validClub = selectedClubs[shot.Club];

      const shotDateOnly = new Date(
        shotDate.getFullYear(),
        shotDate.getMonth(),
        shotDate.getDate()
      );
      const startDateOnly =
        startDate &&
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );
      const endDateOnly =
        endDate &&
        new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

      const validStartDate = !startDate || shotDateOnly >= startDateOnly;
      const validEndDate = !endDate || shotDateOnly <= endDateOnly;


      const session = sessions?.find(session => session._id === shot.session);

      if (!session) {
        return false; // If the shot's session is not found among sessions, exclude the shot.
      }

      const sessionTagIds = session.tags || [];

      const includeTagIds = includeTags.map(tag => tag.id);
      const excludeTagIds = excludeTags.map(tag => tag.id);

      // Include the shot if there are no includeTags, or if the shot's session tags match with at least one includeTag.
      const validInclude = includeTagIds.length === 0 || sessionTagIds.some(tagId => includeTagIds.includes(tagId));

      // Exclude the shot if there are excludeTags and the shot's session tags match with at least one excludeTag.
      const validExclude = excludeTagIds.length > 0 && sessionTagIds.some(tagId => excludeTagIds.includes(tagId));

      const validTags = validInclude && !validExclude;

      return validClub && validStartDate && validEndDate && validTags;
    });
  }, [shotData, selectedClubs, startDate, endDate, includeTags, excludeTags, sessions]);

  const [filterLowQuality, setFilterLowQuality] = useState(false);

  function calculateClubStats(shots) {
    const clubsData = {};

    shots.forEach((shot) => {
      const club = shot.Club;
      if (!clubsData[club]) {
        clubsData[club] = { totalCarry: 0, count: 0, carries: [] };
      }
      clubsData[club].totalCarry += shot.Carry;
      clubsData[club].count += 1;
      clubsData[club].carries.push(shot.Carry);
    });

    Object.keys(clubsData).forEach((club) => {
      const avg = clubsData[club].totalCarry / clubsData[club].count;
      const squareDiffs = clubsData[club].carries.map(
        (carry) => (carry - avg) ** 2
      );
      const avgSquareDiff =
        squareDiffs.reduce((a, b) => a + b, 0) / squareDiffs.length;
      const stdDev = Math.sqrt(avgSquareDiff);

      clubsData[club].average = avg;
      clubsData[club].stdDev = stdDev;
    });

    return clubsData;
  }

  function filterLowQualityShots(shots, clubStats) {
    return shots.filter((shot) => {
      const club = shot.Club;
      const threshold = clubStats[club].average - 1.5 * clubStats[club].stdDev;
      return shot.Carry >= threshold;
    });
  }

  const qualityFilteredShots = filterLowQuality
    ? filterLowQualityShots(filteredShots, calculateClubStats(filteredShots))
    : filteredShots;







  return (
    <>
      <Container>
        <div className="d-flex justify-content-between align-items-center px-1">

          <h4 style={{ display: "flex" }}>
            Filters{" "}
            <OverlayTrigger
              placement="right"
              overlay={
                <ReTooltip id="tooltip-right">
                  Filters apply to all charts and tables on the page.
                </ReTooltip>
              }
            >
              <div style={{ marginLeft: 5, cursor: "pointer" }}>
                <FaQuestionCircle size={16} />
              </div>
            </OverlayTrigger>
          </h4>
          <button onClick={onCapture} style={{ background: "none", border: "none", paddingLeft: "10px", marginBottom: "4px" }}>
            <FiShare size={18} color="grey" />
          </button>
        </div>
        <Row>
          <Col md={6}>
            <ClubSelectionCheckboxes
              clubs={uniqueClubs}
              selectedClubs={selectedClubs}
              toggleClubSelection={toggleClubSelection}
            />
          </Col>

          <Col md={6}>
            <Row className="lowLabel">
              {/* <Col xs={3}>                  </Col> */}
              <Col md="auto">
                <Tooltip id="cleaner-multiline" />

                <div>Quality Filter</div>
              </Col>
              <Col md="auto">
                <a
                  data-tooltip-id="cleaner-multiline"
                  data-tooltip-html="This removes shots that fall 1.5 standard deviations<br /> outside your average carry for that club.  Helpful if you're<br /> trying to understand club data without awful strikes"
                  data-tooltip-place="right"
                >
                  <Form.Check
                    type="switch"
                    id="filter-low-quality-switch"
                    // label="Filter low-quality shots"
                    checked={filterLowQuality}
                    onChange={(e) => setFilterLowQuality(e.target.checked)}
                  />
                </a>
              </Col>
              <Col ></Col>

              <Col md="auto"><span>Include Dates: </span></Col>
              <Col><DateRangePicker onChange={onChange} value={value} /></Col>
            </Row>
          </Col>
        </Row >
        <br />
        <Row>
          <Col md={6}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h5 style={{ marginBottom: 0 }}>Tags to Include</h5>
              <OverlayTrigger
                placement="right"
                overlay={
                  <ReTooltip id="tooltip-right">
                    Add/remove tags to sessions in the 'all sessions' page and then you can cut your data based on tags.
                  </ReTooltip>
                }
              >
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                  <FaQuestionCircle size={16} />
                </div>
              </OverlayTrigger>
            </div>
            <Select className="mt-2"
              isMulti
              onChange={handleIncludeTagsChange}
              options={tagOptions}
            />

          </Col>

          <Col md={6}>
            <h5>Tags to Exclude</h5>
            <Select
              isMulti
              onChange={handleExcludeTagsChange}
              options={tagOptions}
            />
          </Col>
        </Row>

        <br />
        <hr />
        {/* <h3>section</h3> */}
        <Row>
          {warningMessage && <p className="text-danger">{warningMessage}</p>}
        </Row>
        <br></br>
        <Row>
          <Col md={6}>
            <CapturableClubCarryChart
              targetId="club-carry-chart"
              shots={qualityFilteredShots}
              username={user.useSgtNameInExport ? user.sgtName : user.name}
            />
            <CapturableBagMappingBoxPlot
              targetId="bag-mapping-box-plot"
              shots={qualityFilteredShots}
              username={user.useSgtNameInExport ? user.sgtName : user.name}
            />
          </Col>
          {/* <Col md={8}>
            <BagMappingBoxPlot shots={shotData} />
          </Col> */}

          <Col md={6}>
            <CapturableShotScatterPlot
              targetId="shot-scatter-plot"
              shots={qualityFilteredShots}
              selectedClubs={selectedClubs}
              allShotEllipseData={allShotEllipseData}
              startDate={startDate}
              endDate={endDate}
              username={user.useSgtNameInExport ? user.sgtName : user.name}
              qualityFilterState={filterLowQuality}
              includeTags={includeTags}
              excludeTags={excludeTags}
              contextType={ContextTypes.ALL_SHOTS}
            />
          </Col>
        </Row>
        <br />
        <hr />
        {/* <h3>Club Summary</h3> */}
        <Row>
          <Col>
            <CapturableClubStatsTable
              targetId="club-stats-table"
              shots={qualityFilteredShots}
              username={user.useSgtNameInExport ? user.sgtName : user.name}
            />
          </Col>
        </Row>
        <br />
        <hr />
        <Row>
          <Col>

            <CapturableCombinedMetricsComponent
              targetId="combined-metrics-area"
              metric1={metric1} // your value
              metric2={metric2} // your value
              handleMetric1Change={handleMetric1Change} // your handler
              handleMetric2Change={handleMetric2Change} // your handler
              handleTimeGroupingChange={handleTimeGroupingChange} // your handler
              filteredShots={filteredShots} // your value
              timeGrouping={timeGrouping} // your value
              username={user.useSgtNameInExport ? user.sgtName : user.name}
            />
          </Col>
        </Row>
      </Container >
    </>
  );
};

const CapturableStatsContent = WithCapture(StatsContent);

export default Stats;
