const drawCirclesPlugin = {
  id: "drawCircles",
  afterDraw: (chart, args, options) => {
    if (!chart.config.options.plugins.drawCircles) {
      return;
    }

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    ctx.save();
    ctx.globalCompositeOperation = "destination-over"; // Set the z-index to "behind"

    ctx.beginPath();
    ctx.rect(chartArea.left, chartArea.top, chartArea.width, chartArea.height);
    ctx.clip();

    ctx.strokeStyle = "rgba(0, 0, 0, 0.2)"; // Set the color and opacity of the circles
    ctx.lineWidth = 1; // Set the line width of the circles

    const yIntercept = 50;
    const centerY = yAxis.getPixelForValue(0);

    // Compute the x-coordinates of the circle at the points where it intersects the y-axis
    const xLeft = Math.sqrt(yIntercept ** 2 - 0 ** 2) * -1;
    const xRight = Math.sqrt(yIntercept ** 2 - 0 ** 2);
    const xLeftPixel = xAxis.getPixelForValue(xLeft);
    const xRightPixel = xAxis.getPixelForValue(xRight);
    const centerX = (xLeftPixel + xRightPixel) / 2;

    const xRange = xAxis.max - xAxis.min;
    const yRange = yAxis.max - yAxis.min;
    const chartAspectRatio = chartArea.width / chartArea.height;
    const aspectRatioCorrection = chartAspectRatio / (xRange / yRange);

    for (
      let distance = yIntercept;
      distance <= yAxis.max;
      distance += yIntercept
    ) {
      const radius = Math.sqrt(distance ** 2 - 0 ** 2); // true_vertical_distance = sqrt(carry^2 - offline^2)
      const radiusInPixels = Math.abs(
        yAxis.getPixelForValue(0) - yAxis.getPixelForValue(radius)
      );
      let radiusInPixelsX = radiusInPixels * aspectRatioCorrection;
      if (radiusInPixelsX < 0) {
        radiusInPixelsX = Math.abs(radiusInPixelsX);
      }
      const xLeftPixel = xAxis.getPixelForValue(xLeft);
      const xRightPixel = xAxis.getPixelForValue(xRight);
      const centerX = (xLeftPixel + xRightPixel) / 2;
      const labelY = yAxis.getPixelForValue(radius) + 10;

      // Set the font size and color
      ctx.font = "20px Arial";

      // Draw a white rectangle behind the text to hide the y-axis
      const labelWidth = ctx.measureText(distance.toString()).width + 10;
      const labelHeight = 24;

      const labelX = xAxis.getPixelForValue(0) - (labelWidth - 10) / 2;

      //   const labelBgX = labelX - labelWidth / 2;
      //   const labelBgY = labelY - labelHeight / 2;
      //   ctx.fillStyle = "#FFFFFF";
      //   ctx.lineWidth = 4;
      //   ctx.fillRect(labelBgX, labelBgY, labelWidth, labelHeight);

      // Calculate the y-coordinate of the label so that it's centered vertically inside the circle

      ctx.beginPath();
      ctx.fillStyle = "#486581";

      ctx.fillText(distance.toString(), labelX, labelY);

      ctx.ellipse(
        centerX,
        centerY,
        radiusInPixelsX,
        radiusInPixels,
        0,
        0,
        2 * Math.PI
      );
      ctx.stroke();
    }

    ctx.restore();
  },
};

export default drawCirclesPlugin;
