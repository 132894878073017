import React from 'react'

const Bolt = () => (
    (        <div style={{
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       width: "6rem",
       height: "6rem",
       marginTop:"2rem",
       marginBottom: "1rem",
       borderRadius: "50%",
       backgroundColor: "#EDE9FE",
   }}
       className="sm:mx-auto sm:w-6 sm:h-6"
   >   <svg stroke="currentColor" viewBox="0 0 52 52" style={{ width: "3.5rem", height: "3.5rem", color: "#6366F1" }}>
   <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
</svg>
</div>
   )
)


export default Bolt