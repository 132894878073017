import React from "react";
import { useEffect, useRef } from "react";
import { clubDisplayOrder } from "./ClubDisplayOrder";
import { BoxPlotChart } from "@sgratzl/chartjs-chart-boxplot";
import { FiShare } from "react-icons/fi";
import "chart.js/auto";

const BagMappingBoxPlot = ({ shots, onCapture }) => {

  const shotData = shots;

  const clubData = {};

  shotData.forEach((shot) => {
    const { Carry, Club } = shot;

    if (!clubData[Club]) {
      clubData[Club] = { carries: [Carry] };
    } else {
      clubData[Club].carries.push(Carry);
    }
  });

  const calculateMean = (data) => {
    return data.reduce((a, b) => a + b) / data.length;
  };

  const calculateQuartile = (data, percentile) => {
    const sorted = data.slice().sort((a, b) => a - b);
    const index = (percentile / 100) * (sorted.length - 1);
    const result = sorted[Math.floor(index)];
    return result;
  };
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;

    const createChart = () => {
      const ctx = chartRef.current.getContext("2d");

      const clubData = {};

      shotData.forEach((shot) => {
        const { Carry, Club } = shot;

        if (!clubData[Club]) {
          clubData[Club] = { carries: [Carry] };
        } else {
          clubData[Club].carries.push(Carry);
        }
      });

      const clubLabels = Object.keys(clubData);
      clubLabels.sort((a, b) => clubDisplayOrder[b] - clubDisplayOrder[a]);

      /*new attempt */

      const clubDatasets = clubLabels.map((label) => {
        const carries = clubData[label].carries;
        const min = Math.min(...carries);
        const max = Math.max(...carries);
        const median = calculateQuartile(carries, 50);
        const q1 = calculateQuartile(carries, 25);
        const q3 = calculateQuartile(carries, 75);
        const mean = calculateMean(carries);

        return {
          label: label,
          data: [
            {
              min,
              max,
              median,
              q1,
              q3,
              mean,
            },
          ],
        };
      });

      clubDatasets.sort((a, b) => a.mean - b.mean); // Sort by mean carry distance
      const sortedLabels = clubDatasets.map((dataset) => dataset.label);

      const sortedDatset = clubDatasets.map((dataset) => dataset.data[0]);

      chartInstance = new BoxPlotChart(ctx, {
        type: "boxplot",
        data: {
          labels: sortedLabels,
          datasets: [{ data: sortedDatset, label: "Club Carry Data" }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: false,
              text: "Club Carry Data",
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Carry Distance (yards)",
              },
              suggestedMin: 0,
              suggestedMax: 300,
            },
          },
        },
      });
    };

    const destroyChart = () => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    };

    destroyChart();
    createChart();

    return () => {
      destroyChart();
    };
  }, [shotData]);

  return (
    <div className="chart-container">
            <div className="d-flex justify-content-between align-items-center px-1">

      <h4>Bag Mapping</h4>
      <button onClick={onCapture} style={{ background: "none", border: "none" , paddingLeft:"10px", marginBottom:"4px"}}>
          <FiShare size={18} color="grey"/>
        </button>
        </div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default BagMappingBoxPlot;
