const ellipsePlugin = {
  id: "ellipse",
  afterDatasetsDraw: function (chart, args, options) {
    const ctx = chart.ctx;
    const show = chart.options.plugins.ellipse.show;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;
    if (show) {
      chart.data.datasets.forEach((dataset, index) => {
        let output_obj = { points: [] };
        dataset.data.forEach((obj) => {
          output_obj.points.push([obj.x, obj.y]);
        });

        if (chart.isDatasetVisible(index)) {
          const club = dataset.label;
          let ellipseData = options.clubOptions?.[club];
          if (!ellipseData) {
            return;
          }
          function generateEllipsePoints(ellipseData, numPoints = 360) {
            const points = [];
            for (let i = 0; i < numPoints; i++) {
              const theta = (2 * Math.PI * i) / numPoints;
              const x =
                ellipseData.a * Math.cos(theta) * Math.cos(ellipseData.angle) -
                ellipseData.b * Math.sin(theta) * Math.sin(ellipseData.angle) +
                ellipseData.x;
              const y =
                ellipseData.a * Math.cos(theta) * Math.sin(ellipseData.angle) +
                ellipseData.b * Math.sin(theta) * Math.cos(ellipseData.angle) +
                ellipseData.y;
              points.push({ x, y });
            }
            return points;
          }

          function convertPointsToPixels(points, xAxis, yAxis) {
            return points.map((point) => ({
              x: xAxis.getPixelForValue(point.x),
              y: yAxis.getPixelForValue(point.y),
            }));
          }

          const ellipsePoints = generateEllipsePoints(ellipseData);
          const ellipsePointsPixels = convertPointsToPixels(
            ellipsePoints,
            xAxis,
            yAxis
          );

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(ellipsePointsPixels[0].x, ellipsePointsPixels[0].y);
          ellipsePointsPixels.forEach((point) => {
            ctx.lineTo(point.x, point.y);
          });
          ctx.closePath();
          ctx.restore();

          ctx.strokeStyle = dataset.borderColor;
          ctx.lineWidth = 2;
          ctx.stroke();
        }
        // }
      });
    }
  },
};

export default ellipsePlugin;
