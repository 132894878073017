import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { clubDisplayOrder } from "./ClubDisplayOrder";

const DateGroupingSelectionDropdown = ({
  onSelectGroupingDimension,
  selectedGrouping,
}) => {
  const groupingLabels = {
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    quarters: "Quarters",
    years: "Years",
  };

  return (
    <DropdownButton
      variant="secondary"
      id="grouping-dimension-dropdown"
      title={`Select Time Grouping: ${groupingLabels[selectedGrouping]}`}
      onSelect={onSelectGroupingDimension}
    >
      <Dropdown.Item eventKey="days">Days</Dropdown.Item>
      <Dropdown.Item eventKey="weeks">Weeks</Dropdown.Item>
      <Dropdown.Item eventKey="months">Months</Dropdown.Item>
      <Dropdown.Item eventKey="quarters">Quarters</Dropdown.Item>
      <Dropdown.Item eventKey="years">Years</Dropdown.Item>
    </DropdownButton>
  );
};

export default DateGroupingSelectionDropdown;
