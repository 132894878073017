import React, { useContext, useEffect, useState, useRef } from "react";
import { format } from "date-fns";
// import { Alert } from "../../components";
import { AlertDismissable } from "../../components";
import { FaTrash } from "react-icons/fa";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Table, Row, Col } from "react-bootstrap";
// import { ShotDataContext } from "../../context/shotDataContext";
import CreatableSelect from 'react-select/creatable';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import useSessions from "../../hooks/useSessions"
import { useUpdateEllipseData } from "../../hooks/useUpdateEllipseData";

const AllSessions = () => {
  // const hasBeenCalled = useRef(false);
  const navigate = useNavigate();

  const {
    user,
    showAlert,
    tags,
    getUserTags,
    addTagToUser,
    addTagToSession,
    removeTagFromSession
    //updateAllShotEllipses
  } = useAppContext();

  const [showTags, setShowTags] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTagsDict, setSelectedTagsDict] = useState({});
  const [tagsLoading, setTagsLoading] = useState(true);
  const [hasSetInitialSelectedTags, setHasSetInitialSelectedTags] = useState(false);
  const [tagsLoadedForSessions, setTagsLoadedForSessions] = useState(false);
  const { sessions, handleDelete } = useSessions(user);

  const { updateAllShotEllipses } = useUpdateEllipseData();

  useEffect(() => {
    if (!tags) {
      getUserTags(user);
    } else {
      setTagOptions(tags
        .map(tag => ({ value: tag.name, label: tag.name }))
        .sort((a, b) => a.label.localeCompare(b.label))
      );

      // console.log("tags: " + JSON.stringify(tags, null, 2))

      // setTimeout(() => {
      setTagsLoading(false);
      // }, 3000); // 1000 milliseconds = 1 second
    }
  }, [tags])


  useEffect(() => {
    if (showAlert) {
      window.scrollTo(0, 0);
    }
  }, [showAlert]);


  const areTagsPopulatedForSessions = (initialSelectedTags) => {
    return sessions.every(session => initialSelectedTags.hasOwnProperty(session._id));
  };

  useEffect(() => {
    if (!hasSetInitialSelectedTags && sessions && tags) {
      const initialSelectedTags = sessions.reduce((dict, session) => {
        dict[session._id] = session.tags
          ? session.tags.map(tagId => {
            let tagObj = tags.find(tag => tag._id === tagId);
            return tagObj ? { value: tagObj.name, label: tagObj.name, _id: tagObj._id } : null;
          }).filter(tagOption => tagOption) // This filter will remove any null options
          : [];

        return dict;
      }, {});

      setSelectedTagsDict(initialSelectedTags);
      const tagsArePopulated = areTagsPopulatedForSessions(initialSelectedTags);
      setTagsLoadedForSessions(tagsArePopulated);
      setHasSetInitialSelectedTags(true);
    }
  }, [hasSetInitialSelectedTags, sessions, tags]);




  const handleTagsChange = async (session, selectedOptions) => {
    // Convert selected options to array of strings
    let currentState = selectedTagsDict;

    // setSelectedTagsDict(prev => ({ ...prev, [session._id]: selectedOptions }));

    // Add new tags to options
    for (let option of selectedOptions) {
      // Check if the tag exists in general tag options
      if (!tagOptions.some(existingOption => existingOption.value === option.value)) {
        // console.log("about to add tag to user with  value:", option.value);
        const newTag = await addTagToUser(option.value);
        // console.log("newTag:", newTag);
        if (newTag) {  // Only proceed if a new tag was actually created
          const newOption = { value: newTag.name, label: newTag.name };
          setTagOptions(previousOptions => [...previousOptions, newOption]);
          try {
            await addTagToSession(newTag._id, session._id);

            //need to call a function which will remove any ellipses that included this tag
            //  and then recalculate the ellipses

            updateAllShotEllipses(newTag._id)


            setSelectedTagsDict(prev => {
              const updatedSessionTags = prev[session._id] ? [...prev[session._id], newOption] : [newOption];
              return { ...prev, [session._id]: updatedSessionTags };
            });

          } catch (error) {
            // If there was an error adding the tag to the session, remove it from the tagOptions
            setSelectedTagsDict(currentState);


          }
        }
      }

      else if (!session.tags.includes(option.value)) {
        const tagObj = tags.find(tag => tag.name === option.value);
        try {
          await addTagToSession(tagObj._id, session._id);
          currentState = { ...currentState, [session._id]: [...(currentState[session._id] || []), option] };
          setSelectedTagsDict(prev => ({ ...prev, [session._id]: selectedOptions }));

          updateAllShotEllipses(tagObj._id)

        } catch (error) {
          setSelectedTagsDict(currentState);
        }
      }
    }

    let oldTags = currentState[session._id].map(tag => {
      const tagObj = tags.find(t => t.name === tag.value);
      return tagObj ? tagObj._id : null;
    });
    // console.log("oldTags:", oldTags)
    let newTags = selectedOptions.map(tag => {
      const tagObj = tags.find(t => t.name === tag.value);
      return tagObj ? tagObj._id : null;
    }); let tagsToRemove = oldTags.filter(tag => !newTags.includes(tag));
    // console.log("tagsToRemove:", tagsToRemove);

    tagsToRemove.forEach(async oldTag => {
      if (!selectedOptions.some(option => option.value === oldTag)) {
        // console.log("oldTag:", oldTag);
        setSelectedTagsDict(prev => ({ ...prev, [session._id]: selectedOptions }));

        await removeTagFromSession(oldTag, session._id);
        updateAllShotEllipses(oldTag)
      }
    });

  };

  return (
    <Container>
      {!tagsLoading && tags && tagsLoadedForSessions && (
        <Row className="align-items-center">
          <Col><h2>All Sessions</h2></Col>
          <Col xs="auto">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Use Tags"
              checked={showTags}
              onChange={() => setShowTags(prevShowTags => !prevShowTags)}
            />
          </Col>
        </Row>
      )}
      {showAlert && <AlertDismissable />}

      {sessions && (
        <Table striped hover bordered responsive>
          <thead>
            <tr>
              <th>Session</th>
              <th>Session Date</th>
              <th>Imported Date</th>
              <th>Clubs</th>
              <th># Shots</th>
              {showTags &&
                <th>Tags
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        Set tags for a given session and use the tags a as filter on the 'All Stats' page.  Want to delete a tag completely? Head to the settings page.
                      </Tooltip>
                    }
                  >
                    <span style={{ marginLeft: 5, cursor: "pointer" }}>
                      <FaQuestionCircle size={14} />
                    </span>
                  </OverlayTrigger>

                </th>
              }
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {sessions
              .sort((a, b) => new Date(b.sessionDate) - new Date(a.sessionDate))
              .map((session, index) => (
                <tr key={session._id}>
                  <td>{index + 1}</td>
                  <td>{format(new Date(session.sessionDate), "dd MMM yy")}</td>
                  <td>{format(new Date(session.importDate), "dd MMM yy")}</td>
                  <td>{session.clubs?.join(", ")}</td>
                  <td>{session.numberOfShots || 0}</td>
                  {showTags &&
                    <td>
                      <CreatableSelect
                        isMulti
                        options={tagOptions}
                        value={selectedTagsDict[session._id] || []}

                        onChange={selectedOptions => handleTagsChange(session, selectedOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </td>
                  }
                  <td style={{ textAlign: "center" }}>
                    <Button variant="outline-secondary" size="sm"
                      onClick={() => {
                        navigate(`/session-stats/${session._id}`);
                      }}
                    >
                      View
                    </Button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <FaTrash
                      style={{ cursor: "pointer", verticalAlign: "middle" }}
                      onClick={() => handleDelete(session._id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default AllSessions;
