import { useAppContext } from "../../context/appContext";
import { AlertDismissable } from "../../components";
import { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import Wrapper from "../../assets/wrappers/DashboardFormPage";

import TagManagement from "../../components/TagManagement";
import './Settings.css';

import { Form, Button, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";

const Settings = () => {
  const { user, showAlert, displayAlert, updateUser, isLoading, } = useAppContext();

  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [lastName, setLastName] = useState(user?.lastName);
  const [sgtName, setSgtName] = useState(user?.sgtName);
  const [useSgtNameInExport, setUseSgtNameInExport] = useState(user?.useSgtNameInExport);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !lastName) {
      displayAlert();
      return;
    }
    updateUser({ name, email, lastName, sgtName, useSgtNameInExport });
  };

  const tooltip = <Tooltip id="tooltip">We'll use this username instead of your first name when you export images to share with others.</Tooltip>;
  return (
    <Wrapper>
      <style>
        {`  
        .btn-purple {
    background-color: #6366f1;
    color: #fff; // Add this line if you want the text color to be white
    text-decoration: none;
    // display: inline-block;
  }

  .btn-purple:hover {
    background-color:#5a5fd9; // Add a slightly darker color for hover effect
    color: #fff;
    text-decoration: none;
  }
  `}
      </style>
      <Form onSubmit={handleSubmit}>
        <h3>Profile</h3>
        {showAlert && <AlertDismissable />}
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="py-4 align-items-end">
          <Col>
            <Form.Group>
              <Form.Label>SGT Username</Form.Label>
              <Form.Control type="text" value={sgtName} onChange={(e) => setSgtName(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                value={useSgtNameInExport}
                checked={useSgtNameInExport}
                label={
                  <>
                    Use in Exports
                    <OverlayTrigger placement="right" overlay={tooltip}>
                      <span className="info-icon"> <FaQuestionCircle size={16} /></span>
                    </OverlayTrigger>
                  </>
                }
                onChange={(e) => setUseSgtNameInExport(e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isLoading} className="mt-5 btn-purple">
          {isLoading ? "Please Wait..." : "Save changes"}
        </Button>
      </Form>
      <br />
      <hr />
      <TagManagement />
    </Wrapper>
  );
};

export default Settings;
