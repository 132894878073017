import React, { useState, useContext, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ClubSelectionDropdown, DateMappingBoxPlot } from "../../components";
import ShotDataContext from "../../context/shotDataContext";
// import ShotDataProvider from "../../components/ShotDataProvider";
import { clubDisplayOrder } from "../../components/ClubDisplayOrder";
import ThreeLineChart from "../../components/ThreeLineChart";
import DateGroupingSelectionDropdown from "../../components/DateGroupingSelectionDropdown";

const ClubAnalysis = () => {
  return <ClubAnalysisContent />;
};

const ClubAnalysisContent = () => {
  const [selectedClub, setSelectedClub] = useState(null);
  const [groupingDimension, setGroupingDimension] = useState("days");

  const { shotData } = useContext(ShotDataContext);

  const clubs = useMemo(() => {
    if (!shotData) return [];
    const uniqueClubs = new Set(shotData.map((shot) => shot.Club));
    return Array.from(uniqueClubs).sort(
      (a, b) => clubDisplayOrder[a] - clubDisplayOrder[b]
    );
  }, [shotData]);

  const handleSelectClub = (club) => {
    setSelectedClub(club);
  };

  const filteredShots = useMemo(() => {
    if (!shotData || !selectedClub) return [];

    return shotData.filter((shot) => shot.Club === selectedClub);
  }, [shotData, selectedClub]);

  return (
    <ShotDataContext.Consumer>
      {({ shotData }) => {
        const filteredShots = shotData
          ? shotData.filter((shot) => shot.Club === selectedClub)
          : [];

        return (
          <Container>
            <Row>
              <Col md={6}>
                <h1>Club Trend Analysis</h1>
              </Col>

              <Col md={6}>
                <Row>
                  <Col>
                    <ClubSelectionDropdown
                      clubs={clubs}
                      onSelectClub={handleSelectClub}
                    />
                  </Col>
                  <Col>
                    <DateGroupingSelectionDropdown
                      selectedGrouping={groupingDimension}
                      onSelectGroupingDimension={(selectedKey) =>
                        setGroupingDimension(selectedKey)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div>
              Once you've loaded multiple sessions into the system, this view
              will help you understand how consistent you are being with each
              metric and how the average metric and consistency (measured as
              your average +/- one standard deviation) is evolving over time.{" "}
            </div>

            {/* <Row>
              <Col>
                <DateMappingBoxPlot shots={filteredShots} />
              </Col>
            </Row> */}
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"Carry"}
              />
            </Row>
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"Offline"}
              />
            </Row>
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"VLA"}
              />
            </Row>
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"HLA"}
              />
            </Row>
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"BackSpin"}
              />
            </Row>
            <Row className="three-line-chart">
              <br />

              <ThreeLineChart
                shotsData={filteredShots}
                groupingDimension={groupingDimension}
                metric={"SideSpin"}
              />
            </Row>
          </Container>
        );
      }}
    </ShotDataContext.Consumer>
  );
};

export default ClubAnalysis;
