import React from 'react';
import html2canvas from 'html2canvas';
import logoSrc from '../../assets/images/GSV-logo.svg';
import { FiShare } from 'react-icons/fi';
import LoadingMask from '../LoadingMask';
import { Modal, Button } from 'react-bootstrap';

const MIN_LOADING_TIME = 2000;  // Minimum loading time in milliseconds

const withCapture = (Component) => {

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isCapturing: false,
        modalVisible: false,
      };
    }

    handleCaptureClick = () => {
      this.setState({ modalVisible: true });
    };

    handleModalConfirmClick = () => {
      this.setState({ modalVisible: false });
      this.capture();
    };

    handleModalCancelClick = () => {
      this.setState({ modalVisible: false });
    };

    capture = () => {
      const startTime = Date.now();  // Store the time when loading starts

      this.setState({ isCapturing: true });  // Start loading mask

      const chartArea = document.getElementById(this.props.targetId);
      const ellipseToggle = document.getElementById('ellipse-toggle-parent');
      ellipseToggle.style.visibility = 'hidden';  // Hide the ellipse toggle

      // Create header element
      const header = document.createElement("div");
      header.className = "header";
      header.style.display = "flex";
      header.style.justifyContent = "space-between";
      header.style.alignItems = "center";
      header.style.width = "100%";
      header.style.padding = "15px";
      header.style.backgroundColor = "#f0f0f0";
      header.style.marginBottom = "10px";


      const logo = document.createElement("img");
      logo.src = logoSrc;
      logo.alt = "logo";
      logo.style.maxHeight = "60px"; // Set the max height of the logo


      const usernameElement = document.createElement("p");
      usernameElement.textContent = this.props?.username || "test name"; // assuming you're passing username as a prop

      const dateElement = document.createElement("p");
      dateElement.textContent = new Date().toLocaleDateString();

      // Add all elements to header
      header.appendChild(usernameElement);
      header.appendChild(logo);
      header.appendChild(dateElement);

      // Add header to chart area
      chartArea.insertBefore(header, chartArea.firstChild);

      // Capture screenshot
      html2canvas(chartArea, { scale: 2 })
        .then((canvas) => {
          // Convert canvas to data URL
          const imgData = canvas.toDataURL("image/png");

          // Create a link element
          const link = document.createElement("a");
          link.download = 'GolfSwingVision-export.png';  // set the download file name
          link.href = imgData;
          const endTime = Date.now();  // Store the time when processing ends
          const elapsedTime = endTime - startTime;
          const remainingTime = Math.max(MIN_LOADING_TIME - elapsedTime, 0);

          // Remove header after capture
          if (chartArea.contains(header)) {
            chartArea.removeChild(header);
          }

          // Remove loading mask and trigger the download after ensuring a minimum loading time
          setTimeout(() => {
            this.setState({ isCapturing: false });
            //   document.body.appendChild(link);  // append the link to body
            link.click();  // trigger the download
            //   document.body.removeChild(link);  // remove the link from body
          }, remainingTime);
        })
        .catch(err => {
          // Handle error
          console.error("Failed to capture screenshot:", err);
          this.setState({ isCapturing: false });  // Stop loading mask immediately on error
        })
        .finally(() => {
          // Remove header after capture
          const endTime = Date.now();  // Store the time when processing ends
          const elapsedTime = endTime - startTime;
          const remainingTime = Math.max(MIN_LOADING_TIME - elapsedTime, 0);
          if (chartArea.contains(header)) {
            chartArea.removeChild(header);
          }
          ellipseToggle.style.visibility = 'visible';  // Show the ellipse toggle

          setTimeout(() => {
            this.setState({ isCapturing: false });
          }, remainingTime);
        });
    };


    render() {
      const { isCapturing, modalVisible } = this.state;
      const { targetId } = this.props;

      return (
        <>
          {isCapturing && <LoadingMask />}
          <div id={targetId}>
            <Component {...this.props} onCapture={this.handleCaptureClick} />
            <Modal show={modalVisible} onHide={this.handleModalCancelClick}>
              <Modal.Header closeButton>
                <Modal.Title>Image Save Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {targetId === 'full-page'
                  ? "Save the entire page as a single image to share with your coach or friends. Or click the share button on single charts to export any of them individually."
                  : "Save this graphic as an image to share with your coach or friends!"}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleModalCancelClick}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.handleModalConfirmClick}>
                  Save Image
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      );
    }
  };
};

export default withCapture;
