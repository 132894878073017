import { createContext, useContext } from "react";

const ShotDataContext = createContext();

const useShotData = () => {
  const { shotData, getAverageCarryPerClub } = useContext(ShotDataContext);
  return { shotData, getAverageCarryPerClub };
};

export { ShotDataContext, useShotData };
export default ShotDataContext;
