import React, { useState, useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import './TagManagement.css'


const TagManagement = () => {
    const { user, tags, clearAlertNow, getUserTags, addTagToUser, deleteTagFromUser, getSessionCountForTag } = useAppContext();
    const [tagOptions, setTagOptions] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [toBeDeletedTag, setToBeDeletedTag] = useState(null);
    const [sessionCount, setSessionCount] = useState(0);

    useEffect(() => {
        if (user && !tags) {
            getUserTags(user);
        }
    }, [user, getUserTags, tags]);

    useEffect(() => {
        if (tags) {
            setTagOptions(tags.map(tag => ({ value: tag.name, label: tag.name, id: tag._id })));
            setSelectedTags(tags.map(tag => ({ value: tag.name, label: tag.name, id: tag._id })));
        }
    }, [tags]);

    const getTagUsageCount = async (tagId) => {
        // Placeholder function, in the real implementation, this would fetch the count of sessions using the tag
        // Let's assume it returns a count of 10 for demonstration purposes

        const count = await getSessionCountForTag(tagId);

        return count;
    };

    const handleChange = async (allTags) => {
        if (!allTags) allTags = []; // Handle null (when all tags are deleted)

        // console.log('allTags', allTags);

        const deletedTag = selectedTags.find(tag =>
            !allTags.some(newTag => newTag.value === tag.value)
        );

        // console.log("deletedTag:", deletedTag);
        if (deletedTag) {
            const count = await getTagUsageCount(deletedTag.id);
            setSessionCount(count);
            setToBeDeletedTag(deletedTag);
            setShowConfirmDelete(true);
            return;
        }

        // Check for added tags
        const addedTag = allTags.find(allTags =>
            !selectedTags.some(tag => tag.value === allTags.value)
        );


        if (addedTag) {
            addTagToUser(addedTag.value);  // Assuming your addTagToUser function takes the tag's name as an argument
            setSelectedTags(allTags);
        }


    };


    const confirmDelete = async () => {
        clearAlertNow();
        try {
            // console.log("inside confirmDelete with toBeDeletedTag:", toBeDeletedTag);
            const updatedTags = await deleteTagFromUser(toBeDeletedTag.id);

            // Convert the tags to the format used by the CreatableSelect
            const updatedSelectedTags = updatedTags.map(tag => ({ value: tag.name, label: tag.name, id: tag._id }));

            setSelectedTags(updatedSelectedTags);
            setShowConfirmDelete(false);
            setToBeDeletedTag(null);
        } catch (error) {
            console.error('Error deleting tag: ', error);
            // You can display an error notification here if necessary
        } finally {
            // Regardless of success or failure, we close the modal.
            setShowConfirmDelete(false);
            setToBeDeletedTag(null);
        }
    };


    const closeDeleteConfirmation = () => {
        setShowConfirmDelete(false);
        setToBeDeletedTag(null);
    };

    return (
        <div className="tag-management-section">
            <h4>Tag Management</h4>
            <Row className="mb-3">
                <Col>
                    <CreatableSelect
                        isMulti
                        options={tagOptions}
                        value={selectedTags}
                        placeholder="Add a new tag or select from the list..."
                        onChange={handleChange}
                        isClearable={false}  // Add this prop to hide the clear indicator
                        components={{ DropdownIndicator: null }}
                        className='creatable-select-container'
                    />
                </Col>
            </Row>

            <Modal show={showConfirmDelete} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deleting the tag "{toBeDeletedTag?.label}" will remove it from {sessionCount} sessions. Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                {/* <Col md={4}>
                    <Button onClick={() => alert('Perform some action if necessary')}>Some Action</Button>
                </Col> */}
            </Row>
        </div>
    );
};

export default TagManagement;
