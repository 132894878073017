import React, { useContext, useEffect, useState, CSSProperties } from "react";
import { useAppContext } from "../context/appContext";
import {

    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import SyncLoader from "react-spinners/SyncLoader";


import "./ShotScatterPlot.css";
import { Scatter } from "react-chartjs-2";
import { Form } from "react-bootstrap";
import { FiShare } from "react-icons/fi";
import { colorsList } from "./ClubDisplayOrder";




const ShotScatterPlot = ({
    shots,
    plugins,
    ellipseOptions,
    allShotEllipseData,
    onCapture,
    ellipsesAreLoading
}) => {
    const shotData = shots;
    const [showEllipses, setShowEllipses] = useState(false);
    const { sessions } = useAppContext();

    const getUniqueClubs = (shotData) => {
        const clubsSet = new Set(shotData.map((shot) => shot.Club));
        return Array.from(clubsSet);
    };

    const createLocalClubColor = (uniqueClubs) => {
        const localClubColor = {};
        uniqueClubs.forEach((club, index) => {
            localClubColor[club] = colorsList[index % colorsList.length];
        });
        return localClubColor;
    };

    const uniqueClubs = getUniqueClubs(shotData);
    const localClubColor = createLocalClubColor(uniqueClubs);

    const getClubColor = (Club) => {
        return localClubColor[Club] || "#000000"; // Default to black if the club is not found in the localClubColor object
    };

    const groupedData = shotData.reduce((acc, shot) => {
        if (!acc[shot.Club]) {
            acc[shot.Club] = {
                data: [{ x: shot.Offline, y: shot.TrueVerticalDistance }],
                backgroundColor: getClubColor(shot.Club),
                borderColor: getClubColor(shot.Club),
            };
        } else {
            const session = sessions?.find(session => session._id === shot.session);
            // console.log("session: ", session);
            let sessionDate;
            if (session) {
                const date = new Date(session?.sessionDate);
                sessionDate = date.toLocaleDateString();
            } else {
                sessionDate = "missing"
                console.error(`No session found with id ${shot.session}`);
            }

            acc[shot.Club].data.push({
                x: shot.Offline,
                y: shot.TrueVerticalDistance,
                session: sessionDate,
            });
        }
        return acc;
    }, {});

    // console.log(groupedData);
    const data = {
        datasets: Object.keys(groupedData).map((Club) => ({
            label: Club,
            data: groupedData[Club].data,
            backgroundColor: getClubColor(Club),
            borderColor: getClubColor(Club),
            borderWidth: 3,
        })),
    };

    const toggleEllipses = (event) => {
        setShowEllipses(event.target.checked);
    };

    const options = {
        plugins: {
            drawCircles: {}, // Add this line to include the custom plugin
            ellipse: {
                show: showEllipses,
                clubOptions: ellipseOptions, // Pass the ellipse options to the plugin
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const trueVerticalDistance = context.raw.y;
                        const offline = context.raw.x;
                        const session = context.raw.session;

                        const carry = Math.sqrt(trueVerticalDistance ** 2 + offline ** 2);
                        return [
                            `Club: ${context.dataset.label}`,
                            `Carry: ${carry.toFixed(0)} yards`,
                            `Offline: ${offline.toFixed(0)} yards`,
                            `Session: ${session}`,
                        ];
                    },
                },
            },
        },
        aspectRatio: 0.75, // Add this line to set the aspect ratio
        scales: {
            x: {
                type: "linear",
                position: "bottom",
                min: -50, // Adjust the min value for the x-axis
                max: 50, // Adjust the max value for the x-axis
            },
            y: {
                min: 0,
                max:
                    Math.ceil(Math.max(...shotData.map((shot) => shot.Carry)) / 50) * 50,
                ticks: {
                    display: false, // Add this line to turn off the y-axis labels
                },
            },
        },
    };

    return (
        <div className="chart-container" style={{ maxHeight: "82vh" }}>
            <div className="d-flex justify-content-between align-items-center px-1">
                <h4>Shot Detail</h4>

                <div className="d-flex justify-content-end align-items-center">
                    {ellipsesAreLoading && (
                        <>

                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        Calculating shot groupings.  This can take a while if you have a large number of shots.  But less time than you spent looking for that ProV1 in the woods!
                                    </Tooltip>
                                }
                            >
                                <div style={{ marginRight: 5, cursor: "pointer" }}>
                                    <SyncLoader color="#6366f1" size="6px" />
                                </div>
                            </OverlayTrigger>                        </>
                    )}
                    <Form id="ellipse-toggle-parent">
                        <Form.Switch
                            id="ellipse-toggle"
                            label="Show Groupings"
                            checked={showEllipses}
                            onChange={toggleEllipses}
                        />
                    </Form>

                    <button onClick={onCapture} style={{ background: "none", border: "none", paddingLeft: "10px", marginBottom: "4px" }}>
                        <FiShare size={18} color="grey" />
                    </button>
                </div>
            </div>{" "}

            <Scatter data={data} options={options} />
        </div>
    );
};

export default ShotScatterPlot;