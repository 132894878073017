import { Outlet } from "react-router-dom";
import { Navbar } from "../../components";
import Wrapper from "../../assets/wrappers/SharedLayout";
// import { useAppContext } from "../../context/appContext";
import ShotDataProvider from "../../components/ShotDataProvider";
import SubNavbar from "../../components/SubNavbar";

const SharedLayout = () => {
  // const { user } = useAppContext();
  return (
    <Wrapper>
      <ShotDataProvider>
        <main className="dashboard">
          {/* <SmallSidebar />
          <BigSidebar /> */}
          <div>
            <Navbar />
            <SubNavbar />
            <div className="dashboard-page">
              <Outlet />
            </div>
          </div>
        </main>
      </ShotDataProvider>
    </Wrapper>
  );
};
export default SharedLayout;
