import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/GolfSwing.json'

const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };
  
  const loadingContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  
  const animationStyles = {
    width: 400,
  };
  
  const loadingTextStyles = {
    marginTop: 10,
    color: '#5A5FD9',
    fontSize: 24,
  };
  
  const LoadingMask = () => (
    <div style={overlayStyles}>
      <div style={loadingContainerStyles} >
        <Lottie animationData={loadingAnimation} style={animationStyles} width={"400px"}/>
        <p style={loadingTextStyles}>Exporting...</p>
      </div>
    </div>
  );
  
export default LoadingMask;
