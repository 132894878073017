import Wrapper from "../assets/wrappers/Navbar";
// import { FaUserCircle, FaCaretDown } from "react-icons/fa";
import Logo from "./Logo";
import { useAppContext } from "../context/appContext";
// import { useState } from "react";
// import { Button } from "react-bootstrap";

const Navbar = () => {
  // const [showLogout, setShowLogout] = useState(false);
  const { logoutUser } = useAppContext();
  return (
    <Wrapper>
      <div className="nav-center">
        {/* <button className="toggle-btn" onClick={toggleSidebar}>
          <FaBars />
        </button> */}
        {/* <span></span> */}
        <div>
          <Logo />
          {/* <h3 className="logo-text">Golf Swing Vision</h3> */}
        </div>
        
        <div className="btn-container">
          
          {/* <Button
            className="btn"
            variant="outline-secondary"
            size="sm"
            onClick={() => setShowLogout(!showLogout)}
          >
            <FaUserCircle />
            {user && user.name}
            <FaCaretDown />
          </Button> */}

          
          {/* <div className={showLogout ? "dropdown show-dropdown" : "dropdown"}> */}
            <button onClick={logoutUser} className="dropdown-btn btn-sm" 
>
              logout
            </button>
          {/* </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;
