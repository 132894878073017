import React from 'react';
import { Container, Row, Col, Card, CardGroup, Image, ListGroup, Button } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { FaArrowDown, FaCheck } from 'react-icons/fa';
import './Landing2.css'
import Bolt from '../components/Bolt';
import Logo from '../assets/images/GSV-logo.png'
import bagMapping from '../assets/images/mapping.png'
import patterns from '../assets/images/patterns.png'
import trends from '../assets/images/trends.png'
import mixandmatch from '../assets/images/mixandmatch.png'




const Landing2 = () => (
  <>
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={Logo}
            // width="40"
            height="60"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }} // Using inline styles for applying margin
            alt="Golf Swing Vision logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Link
              to="/register"
              state={{ isMember: true }}
              className="nav-link"
            >Log In</Link>
            <Link
              to="/register"
              className="nav-link"
            >Sign Up</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    <Container fluid className="p-4 bg-white">


      <Row>
        <Col className="text-center">
          <h1 className=" mb-6">Unlock your Swing<span className='title'>Vision</span></h1>
          <div>The tool you've been missing to find hidden insights in your range data.</div><br />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          <Card className="text-center border-0">
            <div className='d-flex justify-content-center'>
              <Bolt />
            </div>

            <Card.Title>
              <h5><b>Stop hitting blindly</b></h5>
            </Card.Title>
            <Card.Body>
              <p>Playing countless rounds and can't understand what's going wrong?  Wondering how to get more out of your range sessions?  Unlock the power of the data you already have to help improve your game.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center border-0">
            <div className='d-flex justify-content-center'>
              <Bolt />
            </div>
            <Card.Title>
              <h5><b>Identify Gaps</b></h5>
            </Card.Title>
            <Card.Body>
              <p>Finally put numbers to your clubs and identify outliers.  See gaps in your launch angles that results in holes in your distance.  Identify your misses and focus your practice.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* middle block */}
      <Row className="justify-content-md-center py-5 bg-white">
        <Col md={8} className="p-4">
          <Card className="border-1 shadow-sm rounded overflow-hidden">
            <Row className="no-gutters">
              <Col sm={6}>
                <div className="position-relative">
                  {/* <div> */}
                  <img src="./markus-spiske-wn35CKouahQ-unsplash.jpg" alt="Swing analyzer" className="position-relative img-clip-path" />
                  {/* <svg viewBox="0 0 20 104" fill="currentColor" style={{position: "absolute", top: "0", right: "0", height: "100%", zIndex: "2"}} className="d-none text-white lg:d-block">
              <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"></polygon>
            </svg> */}
                  {/* </div> */}
                </div>
              </Col>
              <Col sm={6} className="d-flex flex-column justify-content-center p-2 p-sm-4">
                <Card.Body>

                  {/* <Card.Title as="h5" className="mb-3 mb-lg-5 text-3xl font-extrabold leading-none sm:text-4xl">Whether you're chasing scratch or a weekend warrior, we're here to help get the most out of your sim data.</Card.Title> */}

                  <Card.Text className="mb-3 mb-lg-5 text-large text-gray-800">Whether you're chasing scratch or a weekend warrior, we're here to help get the most out of your sim data.</Card.Text>
                  <div className="d-flex align-items-center">
                    <Link
                      to="/register">
                      <Button variant="primary" size="lg" className="mr-3 custom-btn-large">Get started Now</Button>
                    </Link>                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-md-center text-left">
        <Col lg={6} className="py-5">

          <ListGroup variant="flush">
            <ListGroup.Item className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <div className="circle-icon">
                  <FaArrowDown />
                </div>
              </div>
              <div className="ms-4">
                <h5 className="mb-1">Practice on the range</h5>
                <p>Do your normal range session, in GS Pro (others coming soon - get in touch if you are interested).</p>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <div className="circle-icon">
                  <FaArrowDown />
                </div>
              </div>
              <div className="ms-4">
                <h5 className="mb-1">Export your session data</h5>
                <p>Make sure to export your data.  And don't rename the file.  We'll use it to work our magic.</p>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <div className="circle-icon">
                  <FaArrowDown />
                </div>
              </div>
              <div className="ms-4">
                <h5 className="mb-1">Easily Import Your Data</h5>
                <p>Drag and drop the CSV for one or more range sessions into SwingVision and we'll automatically pull in all the required data.</p>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <div className="circle-icon">
                  <FaCheck />
                </div>
              </div>
              <div className="ms-4">
                <h5 className="mb-1">Automatically Identify Trends And Insights</h5>
                <p>Instantly see insights for your session - key metrics, their consistency and more.  And SwingVision will automatically show trend analysis over time - so you'll know what needs work and where you are improving.</p>
              </div>
            </ListGroup.Item>
            {/* Repeat for other items... */}
          </ListGroup>
        </Col>

      </Row>

      <Container fluid className='vw-50'>
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Card className='mb-3'>
              <Card.Img variant="top" src={patterns} className="max-width-img" />
              <Card.Body>
                <Card.Title>Identify Patterns</Card.Title>
                <Card.Text>
                  Analzye data from one session or any custom range to identify patterns in your sessions.
                </Card.Text>
                {/* <Button variant="primary" className='custom-btn'>Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card >
              <Card.Img variant="top" src={bagMapping} className="max-width-img" />
              <Card.Body>
                <Card.Title>Map Your Bag</Card.Title>
                <Card.Text>
                  Easily see average carry as well as dispersion for each club in your bag - find those gaps!
                </Card.Text>
                {/* <Button variant="primary" className='custom-btn'>Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Card className='mb-3'>
              <Card.Img variant="top" src={trends} className="max-width-img" />
              <Card.Body>
                <Card.Title>Analyze Trends</Card.Title>
                <Card.Text>
                  See how your key metrics are trending over time.  By day or by year, you'll see what's improving.
                </Card.Text>
                {/* <Button variant="primary" className='custom-btn'>Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card >
              <Card.Img variant="top" src={mixandmatch} className="max-width-img" />
              <Card.Body>
                <Card.Title>Mix and Match</Card.Title>
                <Card.Text>
                  Create custom views to put metrics of your choice side by side.  Unlock insights on your game.
                </Card.Text>
                {/* <Button variant="primary" className='custom-btn'>Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
      <div className="d-flex justify-content-center">
        <Link
          to="/register">
          <Button variant="primary" size="lg" className="mr-3 custom-btn-large">Get started Now</Button>
        </Link>
      </div>
    </Container >


    {/* footer */}

    <footer className="bg-light py-2" style={{ overflow: 'hidden' }} // Using inline styles for applying margin
    >
      <Row>
        <Col md={4} sm={6} className='px-5 mt-5'>
          <h5 className="text-uppercase mb-4">About Us</h5>
          <p>We love golf. We love data. And we love technology. Enjoy Golf Swing<span className='title'>Vision</span> for free.</p>
        </Col>
        <Col md={4} sm={6} className='px-5 mt-5'>
          <h5 className="text-uppercase mb-4">Contact</h5>
          <p><a href="mailto:support@golfswingvision.com">support@golfswingvision.com</a></p>
        </Col>
        <Col md={4} sm={6} className='px-5 mt-5'>
          <h5 className="text-uppercase mb-4">Donate</h5>
          <p>Want to show your ❤️ and help cover the hosting costs? <a href='https://www.paypal.com/donate/?hosted_button_id=QWK885RERJY3A'>Donate here</a></p>
        </Col>
      </Row>
    </footer >
  </>
);

export default Landing2;
