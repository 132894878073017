import { Bar } from "react-chartjs-2";
import { clubDisplayOrder, colorsList } from "./ClubDisplayOrder";
import { FiShare } from "react-icons/fi";

const ClubCarryChart = ({ shots, onCapture }) => {
  const shotData = shots;
  const clubData = {};

  shotData.forEach((shot) => {
    const { Carry, Club } = shot;

    if (!clubData[Club]) {
      clubData[Club] = { Carry, count: 1 };
    } else {
      clubData[Club].Carry += Carry;
      clubData[Club].count += 1;
    }
  });

  const clubLabels = Object.keys(clubData).map((Club) => Club.toString());
  // const clubAverages = clubLabels.map((Club) => {
  //   const clubAverage = clubData[Club].Carry / clubData[Club].count;
  //   return Math.round(clubAverage * 10) / 10;
  // });
  // sort labels and averages based on averages in ascending order
  clubLabels.sort((a, b) => clubDisplayOrder[b] - clubDisplayOrder[a]);

  const sortedClubAverages = clubLabels.map((Club) => {
    const clubAverage = clubData[Club].Carry / clubData[Club].count;
    return Math.round(clubAverage * 10) / 10;
  });

  // clubAverages.sort((a, b) => a - b);

  //coloring
  const getUniqueClubs = (shotData) => {
    const clubsSet = new Set(shotData.map((shot) => shot.Club));
    return Array.from(clubsSet);
  };

  const createLocalClubColor = (uniqueClubs) => {
    const localClubColor = {};
    uniqueClubs.forEach((club, index) => {
      localClubColor[club] = colorsList[index % colorsList.length];
    });
    return localClubColor;
  };

  const uniqueClubs = getUniqueClubs(shotData);
  const localClubColor = createLocalClubColor(uniqueClubs);

  const getClubColor = (Club) => {
    return localClubColor[Club] || "#000000"; // Default to black if the club is not found in the localClubColor object
  };

  //end coloring

  const backgroundColors = clubLabels.map((Club) => getClubColor(Club));
  const borderColors = clubLabels.map((Club) => getClubColor(Club));

  const chartData = {
    labels: clubLabels,
    datasets: [
      {
        label: "Average Carry (yards)",
        data: sortedClubAverages,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Club",
        },
      },
      y: {
        title: {
          display: true,
          text: "Average Carry (yards)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
                  <div className="d-flex justify-content-between align-items-center px-1">

      <h4>Average Carry by Club</h4>
      <button onClick={onCapture} style={{ background: "none", border: "none" , paddingLeft:"10px", marginBottom:"4px"}}>
          <FiShare size={18} color="grey"/>
        </button>

      </div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ClubCarryChart;
