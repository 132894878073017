import { useEffect, useState } from "react";

const useClubSelection = (clubs) => {
  const [selectedClubs, setSelectedClubs] = useState({});

  useEffect(() => {
    let didCancel = false;

    const initialSelectedClubs = {};
    if (!clubs) {
      return;
    }
    clubs.forEach((club) => {
      initialSelectedClubs[club] = true;
    });

    if (!didCancel) {
      if (
        JSON.stringify(initialSelectedClubs) !== JSON.stringify(selectedClubs)
      ) {
        setSelectedClubs(initialSelectedClubs);
      }
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubs]);

  const toggleClubSelection = (club, newState) => {
    setSelectedClubs((prevSelectedClubs) => ({
      ...prevSelectedClubs,
      [club]: newState === undefined ? !prevSelectedClubs[club] : newState,
    }));
  };

  return [selectedClubs, toggleClubSelection];
};

export default useClubSelection;
