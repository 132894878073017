const getMetricAvg = (shots) => {
  const values = shots;
  const sum = values.reduce((acc, val) => acc + val, 0);

  return sum / values.length;
};

const getMetricStdDev = (values) => {
  // console.log("inside get metric std");

  // console.log(values);

  if (!values || values.length === 0) {
    return undefined;
  }

  const mean = values.reduce((a, b) => a + b, 0) / values.length;
  const variance =
    values.reduce((a, b) => a + (b - mean) ** 2, 0) / values.length;
  const stddev = Math.sqrt(variance);
  // console.log("getMetricSDV is returning" + stddev);

  return stddev;
};

export { getMetricAvg, getMetricStdDev };
