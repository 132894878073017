import Wrapper from "../assets/wrappers/BigSidebar";
import { useAppContext } from "../context/appContext";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useEffect } from "react";

const BigSidebar = () => {
  const { markTooltipAsSeen, user, showSidebar } = useAppContext();
  var showWalkthrough = false;
  // console.log("user.seenOnboardingTooltip: ", user.seenOnboardingTooltip);
  if (user) {
    showWalkthrough = !user.seenOnboardingTooltip; // Replace this with the actual logic to determine if the user is new
  }
  const startSecondIntro = () => {
    const secondIntro = introJs();
    secondIntro.setOptions({
      steps: [
        {
          title: "Time to tee off 🏌️",
          intro: "Head to Add Session to get started! ⛳️",
        },
      ],
    });

    secondIntro.onexit(() => {
      markTooltipAsSeen();
    });
    secondIntro.start();
  };

  useEffect(() => {
    if (showWalkthrough) {
      const intro = introJs();

      intro.onexit(() => {
        startSecondIntro();
      });

      intro.start();
    }
  }, [showWalkthrough]);

  return (
    <Wrapper>
      <div
        className={
          showSidebar ? "sidebar-container" : "sidebar-container show-sidebar"
        }
      >
        <div className="content">
          {/* <header>
            <Logo />
          </header> */}
          <NavLinks />
          <div className="steve" />
        </div>
      </div>
    </Wrapper>
  );
};
export default BigSidebar;
