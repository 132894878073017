import React from "react";
import { Container, Col, Row, Dropdown } from "react-bootstrap";

const metricNameMapping = {
  Carry: ["Carry", "avg"],
  "Carry SDV": ["Carry", "sdv"],
  "Total Distance": ["TotalDistance", "avg"],
  "Total Distance SDV": ["TotalDistance", "sdv"],
  Offline: ["Offline", "avg"],
  "Offline SDV": ["Offline", "sdv"],
  "Ball Speed": ["BallSpeed", "avg"],
  "Ball Speed SDV": ["BallSpeed", "sdv"],
  "Peak Height": ["PeakHeight", "avg"],
  "Peak Height SDV": ["PeakHeight", "sdv"],
  HLA: ["HLA", "avg"],
  "HLA SDV": ["HLA", "sdv"],
  VLA: ["VLA", "avg"],
  "VLA SDV": ["VLA", "sdv"],
  Descent: ["Decent", "avg"],
  "Descent SDV": ["Decent", "sdv"],
  BackSpin: ["BackSpin", "avg"],
  "BackSpin SDV": ["BackSpin", "sdv"],
  SideSpin: ["SideSpin", "avg"],
  "SideSpin SDV": ["SideSpin", "sdv"],
};

const MetricDropdown = ({
  metric1,
  metric2,
  timeGrouping,
  handleMetric1Change,
  handleMetric2Change,
  handleTimeGroupingChange,
}) => {
  const metrics = Object.keys(metricNameMapping);

  return (
    <>
      <style>
        {`.fixed-dropdown {
  min-width: 200px; /* Set a fixed width that works well for your dropdowns */
  text-align: left;

}
`}
      </style>
      <div>
        <Row className="d-inline-flex align-items-center">
          <Col className="col-lg-auto">Select Metrics:</Col>
          <Col>
            <Dropdown onSelect={handleMetric1Change}>
              <Dropdown.Toggle
                className="w-100 fixed-dropdown"
                id="metric1"
                variant="secondary"
                size="sm"
              >
                {metric1}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {metrics.map((metric) => (
                  <Dropdown.Item key={metric} eventKey={metric}>
                    {metric}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown onSelect={handleMetric2Change}>
              <Dropdown.Toggle
                id="metric2"
                variant="secondary"
                size="sm"
                className="w-100 fixed-dropdown"
              >
                {metric2}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {metrics.map((metric) => (
                  <Dropdown.Item key={metric} eventKey={metric}>
                    {metric}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown onSelect={handleTimeGroupingChange}>
              <Dropdown.Toggle variant="secondary" size="sm" className="w-100">
                {timeGrouping === ""
                  ? "All Time"
                  : timeGrouping === "year"
                    ? "By Year"
                    : timeGrouping === "month"
                      ? "By Month"
                      : "By Date"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100 fixed-dropdown">
                <Dropdown.Item eventKey="">All Time</Dropdown.Item>
                <Dropdown.Item eventKey="year">By Year</Dropdown.Item>
                <Dropdown.Item eventKey="month">By Month</Dropdown.Item>
                <Dropdown.Item eventKey="date">By Date</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MetricDropdown;
export { metricNameMapping };
