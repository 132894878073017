import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Table, Container, Modal } from "react-bootstrap";
import { useAppContext } from "../context/appContext";
import { clubDisplayOrder } from "./ClubDisplayOrder.js";

const ShotDetailTable = ({ shots, onDeleteShot, updateShot, setClubEdited }) => {
  const [columns, setColumns] = useState([]);
  const [editingClub, setEditingClub] = useState(null);
  const [localShots, setLocalShots] = useState(shots);
  const [showModal, setShowModal] = useState(false);

  const { updateShotClub } = useAppContext();

  useEffect(() => {
    const filterUnwantedKeys = (key, shots) => {
      const unwantedKeys = [
        "_id",
        "session",
        "user",
        "__v",
        "shotDate",
        "calculatedTrajectoryData",
        "calculatedPeakHeight",
        "calculatedLandingPosition",
        "id",
        "TrueVerticalDistance",
        "DistanceToPin",
      ];

      const allZeroValues = shots.every(
        (shot) => shot[key] === 0 || shot[key] === "" || shot[key] === null
      );

      return !(unwantedKeys.includes(key) || allZeroValues);
    };

    const keys = new Set();
    shots.forEach((shot) => {
      Object.keys(shot)
        .filter((key) => filterUnwantedKeys(key, shots))
        .forEach((key) => {
          keys.add(key);
        });
    });

    const orderedColumns = Array.from(keys);

    if (orderedColumns.includes("Club")) {
      orderedColumns.splice(orderedColumns.indexOf("Club"), 1);
      orderedColumns.unshift("Club");
    }

    setColumns(orderedColumns);
  }, [shots]);

  const formatValue = (value) => {
    return typeof value === "number" ? value.toFixed(1) : value;
  };

  const splitCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const handleClubDoubleClick = (shotId, club) => {
    setEditingClub({ shotId, club });
    setShowModal(true);
  };

  const handleClubSelect = (selectedClub) => {
    if (editingClub && selectedClub !== editingClub.club) {
      updateShotClub(editingClub.shotId, selectedClub)  // Backend update
        .then(() => {
          // update shot context
          const updatedShot = {
            ...shots.find((shot) => shot.id === editingClub.shotId),
            Club: selectedClub,
          };
          updateShot(updatedShot); // update context for all shots
          setClubEdited(true);

          // update local shots
          const updatedShots = localShots.map((shot) =>
            shot._id === editingClub.shotId ? updatedShot : shot
          );
          setLocalShots(updatedShots);
        })
        .catch((error) => {
          // Error handling
          console.error('Error updating club:', error);
        });
    }
    setEditingClub(null);
    setShowModal(false);
  };

  useEffect(() => {
    setLocalShots(shots);
  }, [shots]);


  const orderedClubKeys = Object.keys(clubDisplayOrder).sort(
    (a, b) => clubDisplayOrder[a] - clubDisplayOrder[b]
  );
  const clubModal = (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Club</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-custom">
        {orderedClubKeys.map((club) => (
          <p key={club} style={{ cursor: "pointer" }} onClick={() => handleClubSelect(club)}>
            {club}
          </p>
        ))}
      </Modal.Body>
    </Modal>
  );


  return (
    <>
      <style>
        {`
        .scrollable-table {
          overflow-x: auto;
          overflow-y: auto;
        }
        
        /* Fixed first column */
        .scrollable-table tbody tr td:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 5;
        }
        
        /* Fixed first column in the header */
        .scrollable-table thead th:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 15;
        }

        .modal-body-custom {
          max-height: 300px;  /* Adjust this to your needs */
          overflow-y: auto;
        }
        
`}
      </style>
      {clubModal}
      <Container>
        <div className="scrollable-table">
          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>
                    {column === 'Decent' ? 'Descent' : splitCamelCase(column)}
                  </th>
                ))}
                {onDeleteShot && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {localShots.map((shot) => (
                <tr key={shot._id}>
                  {columns.map((column) => (
                    <td key={`${shot._id}-${column}`}>
                      {column === "Club" ? (
                        <div
                          onDoubleClick={() => handleClubDoubleClick(shot._id, shot[column])}
                          style={{ cursor: "pointer" }}
                        >
                          {formatValue(shot[column])}
                        </div>
                      ) : (
                        formatValue(shot[column])
                      )}
                    </td>
                  ))}
                  {onDeleteShot && (
                    <td style={{ textAlign: "center" }}>
                      <FaTrash
                        style={{ cursor: "pointer", verticalAlign: "middle" }}
                        onClick={() => onDeleteShot(shot._id)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
};

export default ShotDetailTable;
