import { useState, useEffect, useRef, useContext } from "react";
// import { refreshEllipseOptions } from "../utils/ellipseUtils";
import { useAppContext } from "../context/appContext";
import { ShotDataContext } from "../context/shotDataContext";
import ContextTypes from "../context/contextTypes";


export function useEllipseData({ contextType, sessionEllipseData, selectedClubs, shots, sessionId, startDate, endDate, qualityFilterState, includeTags, excludeTags }) {
    const { allShotEllipseData, setAllShotEllipseData } = useContext(ShotDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchCount, setFetchCount] = useState(0);

    const [ellipseOptions, setEllipseOptions] = useState({});
    const [filteredEllipseOptions, setFilteredEllipseOptions] = useState({});
    const [ellipsesToUse, setEllipsesToUse] = useState(ellipseOptions);
    const clubsBeingFetched = useRef(new Set());

    const { fetchEllipseDataForShotsAndSession } = useAppContext();

    // console.log("allShotEllipseData: " + JSON.stringify(allShotEllipseData, null, 2))
    // console.log("sessionEllipseData: " + JSON.stringify(sessionEllipseData, null, 2))


    useEffect(() => {
        if (clubsBeingFetched.current.size > 0) {
            setIsLoading(true);
        } else {
            //add a time delay of 2 seconds
            setIsLoading(false);
        }
    }, [fetchCount]);


    useEffect(() => {
        if (contextType === ContextTypes.ALL_SHOTS) { // Logic for handling all shots scenario

            //First extract the default and cleaned ellipse data 
            const allShotsEllipseOptions = extractEllipseOptions(allShotEllipseData, 'ellipseParameters');
            const filteredShotsEllipseOptions = extractEllipseOptions(allShotEllipseData, 'filteredEllipseParameters');
            setEllipseOptions(allShotsEllipseOptions);
            setFilteredEllipseOptions(filteredShotsEllipseOptions);

            if (!selectedClubs) { return }

            // then check for and fetch any missing ellipses
            if (
                (!shots || !ellipseOptions ||
                    Object.keys(selectedClubs).length === 0 ||
                    Object.keys(ellipseOptions).length === Object.keys(selectedClubs).length) && !startDate && !endDate && !includeTags && !excludeTags
            ) {
                return;
            } else { //some ellipse data is missing
                fetchMissingEllipseData();
            }


        } else if (contextType === ContextTypes.SESSION) {
            // Logic for handling session-specific data

            if (shots?.length > 0 && (Object.keys(ellipseOptions).length !== Object.keys(selectedClubs).length) && !startDate && !endDate && sessionId) {
                fetchMissingEllipseData();
            }
        }
    }, [contextType, allShotEllipseData, selectedClubs, shots, sessionId, startDate, endDate, includeTags, excludeTags]);


    const formatDateForComparison = (dateStr) => {
        return new Date(dateStr).setHours(0, 0, 0, 0);
    };


    //only needed for allShotsEllipseData as it stores default and quality-cleaned versions of ellipses
    const extractEllipseOptions = (ellipseData, optionType) => {


        //TODO: nearly working but if I have an ellipse with a date match and a tag match, it seems to still get a bit confused


        const doTagsMatch = (filterTagIds, ellipseTagIds) => {
            // If there are no filter tags and ellipse also has no tags, it's a match.
            if (!filterTagIds.length && !ellipseTagIds.length) return true;

            // If there are no filter tags but ellipse has tags, it's not a match.
            if (!filterTagIds.length) return false;

            // If there are filter tags but ellipse has no tags, it's not a match.
            if (!ellipseTagIds.length) return false;

            // Create sets for easy comparison.
            const filterSet = new Set(filterTagIds);
            const ellipseSet = new Set(ellipseTagIds);

            // Every tag in the filter set must be present in the ellipse set.
            return [...filterSet].every(tag => ellipseSet.has(tag));
        };


        const filteredEllipses = ellipseData?.filter(ellipse => {
            // console.log("Evaluating ellipse:", ellipse);

            // Convert dates to standard format for comparison
            const ellipseStartDate = ellipse.startDate ? formatDateForComparison(ellipse.startDate) : null;
            const ellipseEndDate = ellipse.endDate ? formatDateForComparison(ellipse.endDate) : null;
            const inputStartDate = startDate ? formatDateForComparison(startDate) : null;
            const inputEndDate = endDate ? formatDateForComparison(endDate) : null;


            if (!ellipse) return false;

            // Handle no dates and no tags condition
            if (!startDate && !endDate && !includeTags?.length && !excludeTags?.length) {
                // console.log("Ellipse matches the no dates and no tags condition.");
                return !ellipse?.startDate && !ellipse?.endDate &&
                    !ellipse.includeTags?.length && !ellipse.excludeTags?.length;
            }

            // Check for exact matches on startDate and endDate
            if (startDate && ellipseStartDate !== inputStartDate) {
                // console.log("Excluding ellipse due to startDate mismatch");
                return false;
            }

            if (endDate && ellipseEndDate !== inputEndDate) {
                // console.log("Excluding ellipse due to endDate mismatch");
                return false;
            }

            // Check for exact matches on includeTags and excludeTags
            if (includeTags?.length && !doTagsMatch(includeTags.map(tagObj => tagObj.id), ellipse?.includeTags)) {
                // console.log("Excluding ellipse due to mismatch in includeTags.");
                return false;
            }

            if (excludeTags?.length && doTagsMatch(excludeTags.map(tagObj => tagObj.id), ellipse?.excludeTags)) {
                // console.log("Excluding ellipse due to mismatch in excludeTags.");
                return false;
            }

            // console.log("Ellipse passed all conditions.");
            return true;
        });

        // Now, let's extract the first matching ellipse for each club
        const clubFilteredOptions = {};

        filteredEllipses.forEach(ellipse => {
            if (!clubFilteredOptions[ellipse.club]) {
                clubFilteredOptions[ellipse.club] = ellipse[optionType];
            }
        });

        // console.log("Filtered ellipses:", filteredEllipses);
        return clubFilteredOptions;
    };

    const formatDate = (date) => {
        const dateObj = new Date(date);
        return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    };

    const fetchEllipseDataForClub = async (club) => {
        // console.log("inside fetchEllipseDataForClub for: " + club)

        const doTagsMatch = (filterTagIds, dataTagIds) => {
            const filterSet = new Set(filterTagIds);
            const dataSet = new Set(dataTagIds);

            const isSubset = [...filterSet].every(tag => dataSet.has(tag));

            return isSubset && filterSet.size === dataSet.size;
        };

        const hasMatchingData = (data) => {
            // console.log("Evaluating data for matching conditions:", data);
            // console.log("start date: " + startDate + " end date: " + endDate)

            if (data?.club !== club) return false;

            const dataStartDateStr = data.startDate ? formatDate(data.startDate) : null;
            const dataEndDateStr = data.endDate ? formatDate(data.endDate) : null;

            if (startDate && dataStartDateStr !== formatDate(startDate)) {
                // console.log(`Start date mismatch for club ${club}: Data date is ${dataStartDateStr}, but required is ${formatDate(startDate)}`);
                return false;
            } else {
                // console.log(`Start date match for club ${club}: Data date is ${dataStartDateStr}, and required is ${formatDate(startDate)}`);
            }

            if (endDate && dataEndDateStr !== formatDate(endDate)) {
                // console.log(`End date mismatch for club ${club}: Data date is ${dataEndDateStr}, but required is ${formatDate(endDate)}`);
                return false;
            } else {
                // console.log(`End date match for club ${club}: Data date is ${dataEndDateStr}, and required is ${formatDate(endDate)}`);

            }

            // console.log("includeTags: " + JSON.stringify(includeTags, null, 2))
            // console.log("data.includeTags: " + data.includeTags)

            if (includeTags?.length && !doTagsMatch(includeTags?.map(tagObj => tagObj.id), data.includeTags)) {
                // console.log("Data mismatch in includeTags");
                return false;
            }

            if (excludeTags?.length && !doTagsMatch(excludeTags?.map(tagObj => tagObj.id), data.excludeTags)) {
                // console.log("Data mismatch in excludeTags");
                return false;
            }

            // console.log("Data matches all conditions." + JSON.stringify(data, null, 2));
            return true;
        };

        // console.log("sessionId: " + sessionId)
        if (!sessionId) {
            const existingData = allShotEllipseData?.find(hasMatchingData);

            if (existingData) {
                // console.log("Data already exists for the given conditions. No need to fetch.");
                return;
            }
        } else {
            const existingData = sessionEllipseData?.find(hasMatchingData);
            // console.log("ellipseData" + JSON.stringify(sessionEllipseData, null, 2))

            if (existingData) {
                // console.log("Data already exists for the given conditions. No need to fetch.");
                return;
            }
        }

        if (!clubsBeingFetched.current.has(club)) {
            // console.log("fetching ellipse data for club: ", club, " with startDate: ", startDate, " and endDate: ", endDate, " and includeTags: ", includeTags, " and excludeTags: ", excludeTags);
            let fetchedEllipseData;

            let shotsToFindEllipseFor = shots.filter((shot) => shot.Club === club);
            if (shotsToFindEllipseFor.length < 3) {
                // console.log("not enough shots to find ellipse for club: " + club)
                return;
            }
            clubsBeingFetched.current.add(club);
            setFetchCount(prevCount => prevCount + 1);  // Increment fetchCount

            fetchedEllipseData = await fetchEllipseDataForShotsAndSession({
                club,
                startDate,
                endDate,
                sessionId,
                includeTags: includeTags?.map(tag => tag.id) || null,  // passing only ids
                excludeTags: excludeTags?.map(tag => tag.id) || null   // passing only ids
            });
            // console.log("fetchedEllipseData: " + fetchedEllipseData)
            clubsBeingFetched.current.delete(club);
            setFetchCount(prevCount => prevCount - 1);  // Decrement fetchCount

            if (fetchedEllipseData) {
                // console.log('back with fetchedEllipseData: ', fetchedEllipseData[0].result)
                let ellipseObject = fetchedEllipseData[0].result.ellipseParameters;

                if (contextType === ContextTypes.ALL_SHOTS) {
                    setAllShotEllipseData(prevData => [...prevData, ellipseObject]);
                } else if (contextType === ContextTypes.SESSION) {
                    let cleanedResult = { "club": fetchedEllipseData[0].result.club, "session": fetchedEllipseData[0].result.ellipseParameters.session, "ellipseParameters": fetchedEllipseData[0].result.ellipseParameters.ellipseParameters }
                    sessionEllipseData.push(cleanedResult)
                    const newEllipseOptions = sessionEllipseData?.reduce((acc, ellipse) => {
                        acc[ellipse.club] = ellipse.ellipseParameters;
                        return acc;
                    }, {});
                    setEllipseOptions(newEllipseOptions);
                }
            } else {
                // console.log("no fetchedEllipseData");
            }
        }
    };

    const fetchMissingEllipseData = async () => {
        if (!selectedClubs || Object.keys(selectedClubs).length === 0) {
            return
        }
        const fetchPromises = Object.keys(selectedClubs)
            .filter(club => selectedClubs[club])  // only consider the clubs that are selected
            .map(fetchEllipseDataForClub);
        await Promise.all(fetchPromises);
    };

    useEffect(() => {
        if (qualityFilterState) {
            setEllipsesToUse(() => filteredEllipseOptions);
        } else {
            setEllipsesToUse(() => ellipseOptions);
        }
    }, [qualityFilterState, ellipseOptions, filteredEllipseOptions]);



    return {
        ellipseOptions, setEllipseOptions, filteredEllipseOptions, setFilteredEllipseOptions, ellipsesToUse,
        fetchMissingEllipseData, extractEllipseOptions, isLoading
    };
} 