import { getMetricAvg, getMetricStdDev } from "../utils/calculators";
import { metricNameMapping } from "./MetricDropdown";

const ClubStatsCalculator = ({ shots, metric1, metric2, timeGrouping }) => {
  metric1 = metricNameMapping[metric1];
  metric2 = metricNameMapping[metric2];

  const groups = {};

  shots.forEach((shot) => {
    const club = shot.Club;
    let timeBucket;
    switch (timeGrouping) {
      case "year":
        timeBucket = new Date(shot.shotDate).getFullYear();
        break;
      case "month":
        timeBucket = new Date(shot.shotDate).toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        });
        break;
      case "date":
        timeBucket = shot.shotDate;
        break;
      default:
        timeBucket = "All Time";
        break;
    }

    if (!groups[club]) {
      groups[club] = {};
    }

    if (!groups[club][timeBucket]) {
      groups[club][timeBucket] = {
        [metric1[0] + metric1[1]]: [],
        [metric2[0] + metric2[1]]: [],
      };
    }

    // Check if the keys exist in the current time bucket, and if not, initialize them with an empty array
    if (!groups[club][timeBucket][metric1[0] + metric1[1]]) {
      groups[club][timeBucket][metric1[0] + metric1[1]] = [];
    }
    if (!groups[club][timeBucket][metric2[0] + metric2[1]]) {
      groups[club][timeBucket][metric2[0] + metric2[1]] = [];
    }

    groups[club][timeBucket][metric1[0] + metric1[1]].push(shot[metric1[0]]);
    groups[club][timeBucket][metric2[0] + metric2[1]].push(shot[metric2[0]]);
  });

  const stats = {};

  Object.keys(groups).forEach((club) => {
    stats[club] = {};
    Object.keys(groups[club]).forEach((timeBucket) => {
      const data = groups[club][timeBucket];
      stats[club][timeBucket] = {
        [metric1[0] + metric1[1]]:
          metric1[1] === "avg"
            ? getMetricAvg(data[metric1[0] + metric1[1]])
            : getMetricStdDev(data[metric1[0] + metric1[1]]),
        [metric2[0] + metric2[1]]:
          metric2[1] === "avg"
            ? getMetricAvg(data[metric2[0] + metric2[1]])
            : getMetricStdDev(data[metric2[0] + metric2[1]]),
      };
    });
  });

  return stats;
};

export default ClubStatsCalculator;
