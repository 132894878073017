import { useContext } from "react";
import { ShotDataContext } from "../context/shotDataContext";

export function useUpdateEllipseData() {
    const { allShotEllipseData, setAllShotEllipseData } = useContext(ShotDataContext);
    const updateAllShotEllipses = (tagId) => {
        if (!tagId) {
            console.warn("No tagId provided to updateAllShotEllipses");
            return;
        }

        let removedEllipses = [];

        // Filter out ellipses that have the given tagId in their include/exclude arrays
        const updatedEllipses = allShotEllipseData.filter(ellipse => {
            // Check if the ellipse's includeTags and excludeTags arrays contain the tagId
            const isIncluded = ellipse.includeTags ? ellipse.includeTags.includes(tagId) : false;
            const isExcluded = ellipse.excludeTags ? ellipse.excludeTags.includes(tagId) : false;

            // Only keep the ellipses that don't have the tagId in either array
            if (isIncluded || isExcluded) {
                removedEllipses.push(ellipse._id);
            }

            return !isIncluded && !isExcluded;
        });

        // Update the context with the filtered ellipses
        setAllShotEllipseData(updatedEllipses);

        //don't need to worry about the back end data, it is handled there as tags are edited
        // if (removedEllipses.length > 0) {
        //     console.log("about to attempt to delete ellipses: ", removedEllipses)
        //     deleteEllipses(removedEllipses)
        // }

    };

    return { updateAllShotEllipses };
}
