import React, { useContext, useEffect, useState, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Spinner, Container, Button } from "react-bootstrap";
import { Alert, ShotScatterPlotWrapper } from "../../components";
import { FaQuestionCircle } from "react-icons/fa";
import { ShotDataContext } from "../../context/shotDataContext";
import ShotDetailTable from "../../components/ShotDetailTable";
import ClubStatsTable from "../../components/ClubStatsTable";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ClubSelectionCheckboxes from "../../components/ClubSelectionCheckboxes.js";
import CustomDropdown from "../../components/CustomDropdown.js";
import BallFlightChart from "../../components/BallFlightChart";
import useClubSelection from "./useClubSelection";
import WithCapture from "../../components/charts/withCapture";

import ContextTypes from "../../context/contextTypes.js";
const CapturableClubStatsTable = WithCapture(ClubStatsTable);
const CapturableShotScatterPlot = WithCapture(ShotScatterPlotWrapper);

const SessionStats = () => {
  const {
    user,
    showAlert,
    deleteShotById,
    getSessionById,
    getMostRecentSession,
    getAllSessions,
    sessions,
    fetchEllipseDataForShotsAndSession,
  } = useAppContext();

  const [session, setSession] = useState(null);
  const [allSessions, setAllSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClubs, toggleClubSelection] = useClubSelection(
    session ? session.clubs : []
  );
  const { removeShot, updateShot } = useContext(ShotDataContext);

  const { sessionId } = useParams();
  const navigate = useNavigate();
  const hasBeenCalled = useRef(false);
  const hasGetSessionBeenCalled = useRef(false);

  const [filteredShots, setFilteredShots] = useState([]);
  const [sessionEllipseData, setSessionEllipseData] = useState([]);
  const [deleteCount, setDeleteCount] = useState(0);
  const [clubEdited, setClubEdited] = useState(false);


  useEffect(() => {
    if (session) {
      let results = extractSessionEllipseData(session);
      console.log("setting session ellipse data to: " + JSON.stringify(results, null, 2))
      setSessionEllipseData(results);
    }
  }, [session]);

  useEffect(() => {
    // console.log("setting filtered shots")
    if (session?.shots && session.shots.length > 0) {
      const updatedFilteredShots = session.shots.filter(
        (shot) => selectedClubs[shot.Club]
      );

      setFilteredShots(updatedFilteredShots);
    }
  }, [session, selectedClubs]);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionId) {
        const data = await getSessionById(sessionId, user);
        setSession(data);
        setIsLoading(false);
      } else {
        const data = await getMostRecentSession(user);
        setSession(data);
        setIsLoading(false);
      }
    };

    if (!hasGetSessionBeenCalled.current) {
      fetchData();
      hasGetSessionBeenCalled.current = true;
    }

    return () => { };
  }, [sessionId, user]);

  useEffect(() => {
    // console.log("sessions", sessions);
    // console.log("sessions?.sessions?.length", sessions?.data?.sessions?.length);
    if (sessions?.length > 0) {
      // console.log("sessions.sessions", sessions.data.sessions);
      const sortedSessions = sessions.sort(
        (b, a) => new Date(a.sessionDate) - new Date(b.sessionDate)
      );
      setAllSessions(sortedSessions);
      return;
    }
    const fetchAllSessions = async () => {
      const sessions = await getAllSessions(user);
      if (sessions) {
        const sortedSessions = sessions.sort(
          (b, a) => new Date(a.sessionDate) - new Date(b.sessionDate)
        );
        setAllSessions(sortedSessions);
      } else {
        //TODO - proper error handling
        // console.error("Error fetching all sessions:", sessions);
        setIsLoading(false);
      }
    };

    if (!hasBeenCalled.current) {
      fetchAllSessions();
      hasBeenCalled.current = true;
    }
  }, [user]);

  useEffect(() => {
    if (allSessions.length > 0) {
      setIsLoading(false);
    }
  }, [allSessions]);

  const handleSessionChange = (selectedSessionId) => {
    hasGetSessionBeenCalled.current = false;
    navigate(`/session-stats/${selectedSessionId}`);
  };

  const handleDeleteShot = async (shotId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this shot?"
    );

    if (confirmDelete) {
      const result = await deleteShotById(shotId, sessionId);
      if (result.error) {
        console.error("Error deleting shot:", result.error);
      } else {
        const updatedShots = session.shots.filter(
          (shot) => shot._id !== shotId
        );

        // console.log("session ellipse data pre-filter: " + JSON.stringify(session.ellipseData, null, 2))

        const updatedEllipseData = session.ellipseData.filter(
          (ellipse) => ellipse.club !== result.data.Club
        );

        const updatedSession = {
          ...session,
          shots: updatedShots,
          ellipseData: updatedEllipseData
        };

        setSession(updatedSession);

        // console.log("result.data.Club: " + result.data.Club)
        // console.log(JSON.stringify(updatedSession.ellipseData, null, 2))

        // console.log("Result.data: " + JSON.stringify(result.data, null, 2))
        // console.log("sessionEllipseData: " + JSON.stringify(sessionEllipseData, null, 2))

        const newEllipseData = await fetchEllipseDataForShotsAndSession({
          sessionId: sessionId,
          club: result.data.Club
        });

        // console.log(updatedEllipseData)

        setSessionEllipseData(newEllipseData);


        setDeleteCount(prevKey => prevKey + 1);
        const updatedAllSessions = allSessions.map((s) =>
          s._id === updatedSession._id ? updatedSession : s
        );
        setAllSessions(updatedAllSessions);
        removeShot(shotId);
      }
    }
  };

  const extractSessionEllipseData = (session) => {
    if (session && session.ellipseData) {
      return session.ellipseData.map((ellipse) => ({
        club: ellipse.club,
        session: ellipse.session,
        ellipseParameters: {
          x: ellipse.ellipseParameters.x,
          y: ellipse.ellipseParameters.y,
          a: ellipse.ellipseParameters.a,
          b: ellipse.ellipseParameters.b,
          angle: ellipse.ellipseParameters.angle,
        },
      }));
    } else {
      return [];
    }
  };

  return (
    // <Wrapper>
    <Container>
      <h3>Session Stats</h3>
      {showAlert && <Alert />}

      {isLoading && (
        <div>
          <Spinner animation="border" variant="primary" /> Loading....
        </div>
      )}

      {allSessions.length === 0 && !isLoading ? (
        <div>No sessions available, head to 'Add Session' to load one now!</div>
      ) : (
        session && (
          <>
            <h3>
              {/* {format(new Date(session.sessionDate), "dd MMM yyyy")} */}
              {/* {session.numberOfShots} shots - {session.clubs.join(", ")} */}
            </h3>
            <label htmlFor="sessionSelect">Select a session:</label>
            <CustomDropdown
              session={session}
              allSessions={allSessions}
              handleSessionChange={handleSessionChange}
              urlSessionId={sessionId}
            />
            <br></br>
            <ClubSelectionCheckboxes
              clubs={session.clubs}
              selectedClubs={selectedClubs}
              toggleClubSelection={toggleClubSelection}
              sort="desc"
            />
            <br></br>
            <hr></hr>

            <h4 style={{ display: "flex" }}>
              Averages by Club
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    This table shows the averages by club for the included
                    shots. Edit columns to add/remove items from the view.
                  </Tooltip>
                }
              >
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                  <FaQuestionCircle size={16} />
                </div>
              </OverlayTrigger>
            </h4>
            <CapturableClubStatsTable
              targetId="club-stats-table"
              shots={filteredShots}
              username={user.useSgtNameInExport ? user.sgtName : user.name}
            />
            <br></br>
            {/* <Container> */}
            <Row>
              <Col md={6}>
                <CapturableShotScatterPlot
                  key={deleteCount}
                  targetId="shot-scatter-plot"
                  shots={filteredShots}
                  selectedClubs={selectedClubs}
                  sessionEllipseData={sessionEllipseData}
                  contextType={ContextTypes.SESSION}
                  sessionId={sessionId || session._id} // make sure to pass the sessionId
                  username={user.useSgtNameInExport ? user.sgtName : user.name}
                />
              </Col>
            </Row><br />
            {/* </Container> */}
            <hr /> <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4>Shot by Shot Detail</h4>
              {clubEdited && (
                <i>
                  Click to update the other charts on the page when you are done editing clubs >> {" "}
                  <Button onClick={() => window.location.reload()} variant="info">Update Now</Button>
                </i>
              )}
            </div>
            <ShotDetailTable
              shots={filteredShots}
              onDeleteShot={handleDeleteShot}
              updateShot={updateShot}
              setClubEdited={setClubEdited}
            />
            {/* only display this chart if filtershots[0].calculatedTrajectoryData exists */}
            {filteredShots[0]?.calculatedTrajectoryData.length > 0 && (
              <>
                {" "}
                <hr />{" "}
                <h4 style={{ display: "flex" }}>
                  Ball Flight
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        Note: GSPro does not export ball flight data, nor do
                        they expose their mathematical models for golf ball
                        flight. We've done our best to estimate ball flight
                        based on the data we do have. This chart is a rough
                        approximation of the ball flight, but won't be an exact
                        match for GSPro (it is generally +/- 10%). Turns out,
                        physics is complicated!
                      </Tooltip>
                    }
                  >
                    <div style={{ marginLeft: 5, cursor: "pointer" }}>
                      <FaQuestionCircle size={16} />
                    </div>
                  </OverlayTrigger>
                </h4>
                <BallFlightChart shots={filteredShots} />
              </>
            )}
          </>
        )
      )}
    </Container>
    // </Wrapper>
  );
};

export default SessionStats;
