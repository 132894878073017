import React, { useState } from "react";

import { Container, Table } from "react-bootstrap";
import { clubDisplayOrder } from "./ClubDisplayOrder";
import ClubStatsCalculator from "./ClubStatsCalculator";
import { metricNameMapping } from "./MetricDropdown";
const DateClubAnalysisTable = ({ shots, metric1, metric2, timeGrouping }) => {
  if (!shots) {
    return {};
  }

  const stats = ClubStatsCalculator({ shots, metric1, metric2, timeGrouping });
  if (!stats) {
    return {};
  }
  // console.log(stats["DR"]);

  const metric1Name =
    metricNameMapping[metric1][0] + metricNameMapping[metric1][1];
  const metric2Name =
    metricNameMapping[metric2][0] + metricNameMapping[metric2][1];

  const sortedClubs = Object.keys(stats).sort(
    (a, b) => clubDisplayOrder[a] - clubDisplayOrder[b]
  );

  let columns;

  switch (timeGrouping) {
    case "year":
      columns = Array.from(
        new Set(shots.map((shot) => new Date(shot.shotDate).getFullYear()))
      ).sort((a, b) => a - b);
      break;

    case "month":
      columns = Array.from(
        new Set(
          shots.map((shot) =>
            new Date(shot.shotDate).toLocaleString("en-US", {
              month: "short",
              year: "numeric",
            })
          )
        )
      ).sort((a, b) => new Date(a) - new Date(b));
      break;

    case "date":
      columns = Array.from(new Set(shots.map((shot) => shot.shotDate))).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      break;

    default:
      columns = Object.keys(stats[sortedClubs[0]]);
      break;
  }

  return (
    <>
      <style>
        {`
        .scrollable-table {
          overflow-x: auto;
          overflow-y: auto;
        }
        
        /* Fixed first column */
        .scrollable-table tbody tr td:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 5;
        }
        
        /* Fixed first column in the header */
        .scrollable-table thead th:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 15;
        }
        
`}
      </style>
      <div>
        <div className="scrollable-table">
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Club</th>
                {columns.map((column) => (
                  <th key={column}>
                    {timeGrouping === "date"
                      ? new Date(column).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "2-digit",
                        })
                      : column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedClubs.map((club) => (
                <tr key={club}>
                  <td>{club}</td>
                  {columns.map((column) => (
                    <td key={`${club}-${column}`}>
                      {stats[club][column] && (
                        <>
                          {!isNaN(stats[club][column][metric1Name])
                            ? stats[club][column][metric1Name].toFixed(1)
                            : "--"}
                          <br />
                          {!isNaN(stats[club][column][metric2Name])
                            ? stats[club][column][metric2Name].toFixed(1)
                            : "--"}
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default DateClubAnalysisTable;
